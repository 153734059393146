<template>
  <div id="mindAdd">
    <!-- 跳轉畫面 -->
    <div class="loading" v-if="loading"></div>
    <div class="back-title">
      <div class="back-title_box">
        <div>後台管理系統</div>
        <div class="logout" @click="logout">登出</div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <div class="add_manager">
          管理員: <span>{{ mgrId }}</span>
        </div>
      </div>
      <div class="row mt-4 breadcrumb">
        <ol>
          <li>
            <router-link to="/">
              <span
                ><i class="fa-solid fa-house" style="color: #3881ff"></i></span
              >首頁</router-link
            >
          </li>
          <li></li>
          <li><router-link to="adminPanel">管理頁面</router-link></li>
          <li></li>
          <li>新增</li>
        </ol>
      </div>
      <div class="row mt-4 step">
        <div class="d-flex">
          <div class="boxCircle"><div class="circle -on">1</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle">2</div></div>
          <div class="boxLine"><div class="line"></div></div>
          <div class="boxCircle"><div class="circle">3</div></div>
          <div class="boxLine"><div class="line"></div></div>
          <div class="boxCircle"><div class="circle">4</div></div>
        </div>
      </div>
      <div class="row mt-4 stepText">
        <div class="text -on">填寫文章資訊</div>
        <div class="text">上傳照片</div>
        <div class="text">編輯及預覽</div>
        <div class="text">完成!</div>
      </div>
      <div class="row mt-4 addNew">
        <div class="col-12 add_1 mb-4 row">
          <div
            class="col-2 add_1_left d-flex align-items-center justify-content-start"
          >
            請選擇分類 :
          </div>
          <div class="col-10">
            <b-form-select
              v-model="category"
              :options="options"
            ></b-form-select>
          </div>
        </div>

        <div class="col-12 add_2 mb-4 row">
          <div
            class="col-2 add_2_left d-flex align-items-center justify-content-start"
          >
            請輸入標題 :
          </div>
          <div class="titleInput col-10">
            <b-form-input
              v-model.trim="topic"
              placeholder="請輸入標題"
              @blur="checkTopic"
            ></b-form-input>
            <p :class="{ on: TopicAlert }">標題重複!</p>
          </div>
        </div>

        <div class="col-12 add_3 mb-4 row">
          <div
            class="col-2 add_3_left d-flex align-items-center justify-content-start"
          >
            選擇日期 :
          </div>
          <div class="col-10 add_3_right">
            <div class="col-12 row">
              <div class="col-8">
                <b-input-group class="mb-3" @click="cancelDate">
                  <b-form-input
                    id="example-input"
                    v-model.trim="calendar"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="calendar"
                      button-only
                      right
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onContext"
                      class="calendar"
                    ></b-form-datepicker>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-4 date">
                <input
                  type="radio"
                  v-model="dateToggle"
                  @click="cancelCalendar"
                />
                <p>目前時間</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-2 add_btn m-auto mb-3">
          <button class="goToStep_2" @click="submitAll">送出</button>
        </div>
      </div>
      <div class="row mt-4 top">
        <GotoTop />
      </div>
    </div>
  </div>
</template>

<script>
import GotoTop from "../../components/GotoTop.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      mgrId: "",

      //跳轉icon
      loading: false,

      //分類名字
      category: null,
      options: [
        { value: null, text: "請選擇分類" },
        // { value: "生活分享", text: "生活分享" },
        { value: "中醫文章", text: "中醫文章" },
        { value: "中醫穴道", text: "中醫穴道" },
        { value: "影片", text: "影片" },
        {
          label: "吃喝玩樂",
          options: [
            { value: "好物推薦", text: "好物推薦" },
            { value: "好吃好玩", text: "好吃好玩" },
            { value: "生活雜事", text: "生活雜事" },
          ],
        },
      ],
      //標題名字
      topic: "",
      //標題重複顯示警語
      TopicAlert: false,

      //日期toggle
      calendar: "",
      dateToggle: false,
    };
  },
  components: {
    GotoTop,
  },
  computed: {},
  methods: {
    //每次更新網頁都會檢查管理員權限
    mgrCheck() {
      axios
        .post("php/checkMgrRight.php")
        .then((res) => {
          let info = res.data.mgrId;
          return info;
        })
        .then((res) => {
          if (res) {
            this.mgrId = res;
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          }
        });
    },

    //檢查步驟
    addCheckStep() {
      axios
        .post("php/add/addCheckStep.php")
        .then((res) => {
          if (res.data == "回到步驟2") {
            Swal.fire({
              title: "請從步驟2開始!",
              icon: "warning",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/addStep2");
          } else if (res.data == "回到步驟3") {
            Swal.fire({
              title: "請從步驟3開始!",
              icon: "warning",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/addStep3");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    //日期
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
    },

    //點擊現在按鈕，自選日期變成空白
    cancelCalendar() {
      this.dateToggle = true;
      this.calendar = "";
    },

    //自己寫日期，現在的按鈕取消
    cancelDate() {
      this.dateToggle = false;
    },

    //確認標題是否重複
    checkTopic() {
      let params = new URLSearchParams();
      params.append("topic", this.topic);
      axios.post("php/add/addCheckTopic.php", params).then((res) => {
        if (res.data == "此標題已經存在!") {
          this.TopicAlert = true;
        } else {
          this.TopicAlert = false;
        }
      });
    },

    //存輸入的資料
    submitAll() {
      //跳轉開始
      this.loading = true;

      if (this.category == null) {
        Swal.fire({
          title: "請選擇分類",
          text: "分類不可空白",
          icon: "error",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else if (this.topic == "") {
        Swal.fire({
          title: "請下標題",
          text: "標題不可空白",
          icon: "error",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else if (this.TopicAlert == true) {
        Swal.fire({
          title: "標題重複",
          text: "標題不可重複",
          icon: "error",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else if (this.calendar == "" && this.dateToggle == false) {
        Swal.fire({
          title: "請選擇日期",
          text: "日期不可空白",
          icon: "error",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else {
        //URLSearchParams是JavaScript的内建，用來處理URL查詢的參數
        let params = new URLSearchParams();
        params.append("category", this.category);
        params.append("topic", this.topic);
        params.append("buildDate", this.calendar);

        axios
          .post("php/add/addStep1.php", params)
          .then((res) => {
            // console.log(res.data);
            if (res.data == "新增成功") {
              Swal.fire({
                title: "成功!前往下一步",
                icon: "success",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              });
              this.$router.push("/addStep2");
            }
          })
          .catch((error) => {
            if (error.res) {
              //伺服器返回錯誤碼，顯示哪一行出現錯誤
              console.error(
                "Server responded with non-2xx status:",
                error.res.status,
                error.res.data
              );
            } else if (error.request) {
              //請求發出但沒有收到回應
              console.error("No response received:", error.request);
            } else {
              //請求發生錯誤
              console.error("Error setting up request:", error.message);
            }
          })
          .finally(() => {
            //跳轉隱藏
            this.loading = false;
          });
      }
    },

    //登出
    logout() {
      axios.post("php/logOut.php").then((res) => {
        // console.log(res.data);
        if (res.data == "已登出後台!") {
          this.mgrId = "";
          Swal.fire({
            title: res.data,
            icon: "success",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          this.$router.push("/logIn");
        }
      });
    },
  },
  mounted() {
    this.mgrCheck();
    this.addCheckStep(); //檢查步驟
  },
};
</script>
