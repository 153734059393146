<template>
  <div class="tab">
    <!-- 跳轉畫面 -->
    <div class="loading" v-if="loading"></div>

    <h3>meta關鍵字管理</h3>
    <div class="list">
      <div class="num">
        共 <span>{{ length }}</span> 筆
      </div>
    </div>

    <div class="tab10_content">
      <b-table striped hover :items="items" :fields="fields" class="table10">
        <!-- 標題 -->
        <template v-slot:cell(topic)="data">
          <span v-if="!isEditing || data.item !== editedItem">
            {{ data.item.topic }}
          </span>
          <b-form v-else @submit.prevent>
            <b-form-input
              v-model.trim="editTopic"
              type="text"
              :state="isTopicValid && isTopicNotEmpty ? null : false"
            >
            </b-form-input>
            <b-form-invalid-feedback v-if="!isTopicValid && !isOriginalTopic"
              >標題重複!</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-if="!isTopicNotEmpty"
              >不可空白!</b-form-invalid-feedback
            >
          </b-form>
        </template>

        <!-- 關鍵字 -->
        <template v-slot:cell(metaKeywords)="data">
          <span v-if="!isEditing || data.item !== editedItem">
            {{ data.item.metaKeywords }}
          </span>
          <b-form v-else>
            <b-form-input v-model.trim="editKeyword"></b-form-input>
          </b-form>
        </template>

        <!-- 內容 -->
        <template v-slot:cell(metaDescription)="data">
          <span v-if="!isEditing || data.item !== editedItem">
            {{ data.item.metaDescription }}
          </span>
          <b-form-textarea
            v-else
            v-model.trim="editContent"
            rows="8"
          ></b-form-textarea>
        </template>

        <!-- 狀態 -->
        <template v-slot:cell(metaStatus)="data">
          <span v-if="!isEditing || data.item !== editedItem">
            {{ data.item.metaStatus }}
          </span>
          <select v-else v-model="editStatus">
            <option value="上架">上架</option>
            <option value="下架">下架</option>
          </select>
        </template>

        <!-- 編輯按鈕 -->
        <template v-slot:cell(edit)="data">
          <button class="editBtn" @click="toggleEdit(data.item)">
            {{ isEditing && data.item === editedItem ? "確認" : "編輯" }}
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      fields: [
        {
          key: "metaKeyNo",
          label: "項目編號",
          sortable: true,
        },
        {
          key: "topic",
          label: "標題",
        },
        {
          key: "metaKeywords",
          label: "關鍵字",
        },
        {
          key: "metaDescription",
          label: "敘述",
        },
        {
          key: "metaStatus",
          label: "狀態",
          sortable: true,
        },
        {
          key: "edit",
          label: "編輯",
        },
      ],

      //跳轉icon
      loading: false,

      editStatus: null, //修改狀態
      editKeyword: "", //修改的關鍵字
      editContent: "", //修改的內容
      editTopic: "", //修改的標題

      //檢查標題重複會用到的
      originalTopic: "", //原始標題
      topicExist: false,

      //控制編輯模式
      isEditingEnabled: true,
      isEditing: false,
      editedItem: null,
    };
  },
  components: {},
  computed: {
    ...mapState(["tab_10"]),
    ...mapGetters({ length: "tab10Length" }),

    items() {
      return this.$store.state.tab_10.map((item) => ({
        metaKeyNo: item.metaKeyNo,
        topic: item.topic,
        metaKeywords: item.metaKeywords,
        metaDescription: item.metaDescription,
        metaStatus: item.metaStatus === "0" ? "上架" : "下架",
      }));
    },

    //檢查標題是否空白
    isTopicNotEmpty() {
      return this.editTopic.trim() !== "";
    },

    isTopicValid() {
      return !this.topicExist;
    },

    //檢查編輯的是否為原始的標題
    isOriginalTopic() {
      return this.editTopic === this.originalTopic;
    },
  },
  methods: {
    ...mapActions(["backendTab_10"]),

    //輸入基本資料(開發時input資料用)
    addItemNo() {
      axios.post(`php/admin/metaInsert.php`).then((res) => {
        console.log(res.data);
      });
    },

    //確認標題是否重複
    checkTopic() {
      //檢查標題是否為空白
      if (!this.isTopicNotEmpty) {
        return;
      }

      let params = new URLSearchParams();
      params.append("topic", this.editTopic);
      axios
        .post("php/add/addCheckTopic.php", params)
        .then((res) => {
          if (res.data == "此標題已經存在!") {
            this.topicExist = true;
          } else {
            this.topicExist = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    // 點擊編輯按鈕時觸發
    toggleEdit(item) {
      // 如果點擊編輯按鈕，保存原始標題(原始標題不會顯示重複警語)
      if (!this.isEditing) {
        this.originalTopic = item.topic;
      }

      // 進入或退出編輯模式切換
      this.isEditing = !this.isEditing;
      this.editedItem = item;

      // 如果進入編輯模式，則設置編輯時的初始值
      if (this.isEditing && this.isEditingEnabled && item === this.editedItem) {
        this.editStatus = item.metaStatus;
        this.editKeyword = item.metaKeywords;
        this.editContent = item.metaDescription;
        this.editTopic = item.topic;
      } else {
        // 如果退出編輯模式
        // 如果標題是空白或標題重複，或者標題與原始標題相同，則執行保存動作
        if (!this.isTopicNotEmpty) {
          Swal.fire({
            title: "不可空白!",
            text: "請重新輸入",
            icon: "error",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          return;
        } else if (!this.isTopicValid && !this.isOriginalTopic) {
          Swal.fire({
            title: "標題重複!",
            text: "請重新輸入",
            icon: "error",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          return;
        } else {
          //跳轉開始
          this.loading = true;

          //儲存更新的內容
          this.saveEditItem();
        }
      }
    },

    //儲存更新的內容
    async saveEditItem() {
      let form = new FormData();
      form.append("itemNo", this.editedItem.metaKeyNo);
      form.append("topic", this.editTopic);
      form.append("metaKeywords", this.editKeyword);
      form.append("metaDescription", this.editContent);
      form.append("metaStatus", this.editStatus);

      try {
        let res = await axios.post("php/admin/updateTab10.php", form);
        if (res.data === "尚未驗證權限") {
          this.showSwal("帳密錯誤!", "請重新輸入", "error");
          this.$router.push("/logIn");
        } else if (res.data === "更新成功") {
          await this.showSwal("更新成功!", "", "success");
          window.location.reload();
        } else {
          this.showSwal("修改錯誤!", "請聯絡管理員", "error");
          window.location.reload();
          this.loading = false;
        }
      } catch (error) {
        console.log(error.response);
      }
    },

    //呼叫Swal彈跳視窗
    showSwal(title, text, icon) {
      return Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonText: "確定",
        confirmButtonColor: "#72B9EF",
      });
    },
  },
  watch: {
    //每次editTopic發生變化時檢查是否標題有重複
    editTopic(newValue) {
      if (newValue.trim() !== "") {
        //檢查標題是否為空白
        if (!this.isTopicNotEmpty) {
          return;
        }

        //當編輯的標題不是原始標題時才檢查
        if (!this.isOriginalTopic) {
          this.checkTopic();
        } else {
          //如果是原始標題就直接回重置狀態
          this.topicExist = false;
        }
      }
    },
  },
  mounted() {
    this.backendTab_10();
    // this.addItemNo(); //輸入全部基本資料
  },
};
</script>
