<template>
  <div id="mingContent">
    <!-- 每次進來先輸入密碼 -->
    <div id="testLogin" v-if="!content">
      <div class="logIn">
        <div class="logInBox">
          <div class="lonInForm">
            <h4>請輸入密碼</h4>
            <form @submit.prevent="sendForm">
              <input type="password" placeholder="密碼" v-model="password" />
              <div class="btn"><button type="submit">登入</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- 文章內容 -->
    <div class="container" v-if="content">
      <div class="row preview">
        <div class="col p-4 mb-5">
          <div class="topicImage mb-4">
            <img
              :src="content[0].topicImage"
              :alt="content[0].topic"
              :title="content[0].topic"
            />
          </div>

          <h1>{{ content[0].topic }}</h1>
          <ul>
            <li>
              <span><i class="fa-regular fa-clock"></i></span>{{ getDate }}
            </li>
          </ul>
          <div class="mingContent" v-html="getContent"></div>
        </div>
      </div>
    </div>

    <GotoTop />
  </div>
</template>

<script>
import md from "@/markdown";
import axios from "axios";
import Swal from "sweetalert2";
import GotoTop from "../../components/GotoTop.vue";

export default {
  components: {
    GotoTop,
  },
  data() {
    return {
      password: "",
      md: md,
      content: "",
    };
  },
  methods: {
    sendForm() {
      let params = new URLSearchParams();
      params.append("password", this.password);

      axios
        .post("php/testLogin.php", params)
        .then((res) => {
          // console.log(res.data.testPsw);
          if (res.data.success) {
            Swal.fire({
              title: "驗證成功!",
              icon: "success",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.fetchContent();
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
          }
        })
        .catch((error) => console.log(`錯誤: ${error}`));
    },

    fetchContent() {
      axios
        .post("php/testPage.php")
        .then((res) => {
          //   console.log(res.data);
          this.content = res.data;
        })
        .catch((error) => console.log(`錯誤:${error}`));
    },
  },
  computed: {
    getDate() {
      return this.content[0].buildDate.slice(0, 10);
    },
    getContent() {
      return md.render(this.content[0].content);
    },
  },
  mounted() {
    // this.fetchContent();
  },
};
</script>
