<template>
  <div class="tab">
    <h3>管理員資訊</h3>
    <div class="list">
      <div class="num">
        共 <span>{{ length }}</span> 筆
      </div>
    </div>

    <div class="tab1_content">
      <b-table striped hover :items="items" :fields="fields" class="table1">
        <!-- 密碼 -->
        <template v-slot:cell(mgrPsw)="data">
          <span v-if="!isEditing || data.item !== editedItem">
            {{ data.item.mgrPsw }}
          </span>
          <b-form v-else>
            <b-form-input
              v-model.trim="editMgrPsw"
              :state="validation"
            ></b-form-input>
            <b-form-invalid-feedback :state="validation">
              不可空白
            </b-form-invalid-feedback>
          </b-form>
        </template>

        <!-- 狀態 -->
        <template v-slot:cell(mgrStatus)="data">
          <span v-if="!isEditing || data.item !== editedItem">{{
            data.item.mgrStatus
          }}</span>
          <select v-else v-model="editStatus">
            <option value="正常">正常</option>
            <option value="停權">停權</option>
          </select>
        </template>

        <!-- 編輯 -->
        <template v-slot:cell(edit)="data">
          <button class="editBtn" @click="toggleEdit(data.item)">
            {{ isEditing && data.item === editedItem ? "確認" : "編輯" }}
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      fields: [
        {
          key: "mgrNo",
          label: "編號",
        },
        {
          key: "mgrId",
          label: "帳號",
        },
        {
          key: "mgrPsw",
          label: "密碼",
        },
        {
          key: "mgrStatus",
          label: "狀態",
        },
        {
          key: "edit",
          label: "編輯",
        },
      ],

      editStatus: null,
      editMgrPsw: "",

      editedItem: null,
      isEditing: false,
    };
  },
  components: {},
  computed: {
    ...mapState(["tab_1"]),
    ...mapGetters({
      length: "tab1Length",
    }),

    items() {
      return this.tab_1.map((item) => {
        let mgrNo = item.mgrNo;
        let mgrId = item.mgrId;
        let mgrPsw = item.mgrPsw;
        let mgrStatus = item.mgrStatus === "0" ? "正常" : "停權";
        return { mgrNo, mgrId, mgrPsw, mgrStatus };
      });
    },

    validation() {
      return this.editMgrPsw.length > 0;
    },
  },
  methods: {
    ...mapActions(["backendTab_1"]),

    //編輯模式與非編輯模式的切換
    toggleEdit(item) {
      //檢查是否處於編輯模式()
      if (this.isEditing && item == this.editedItem) {
        if (this.editMgrPsw === "") {
          return;
        } else {
          this.isEditing = false;

          this.saveEditItem();
        }
      } else {
        //進入編輯模式
        this.isEditing = true;
        this.editedItem = item;

        this.editStatus = item.mgrStatus;
        this.editMgrPsw = item.mgrPsw;
      }
    },

    //儲存更新的內容
    saveEditItem() {
      if (!this.editMgrPsw) {
        Swal.fire({
          title: "密碼不能為空白!",
          icon: "error",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        return;
      }

      let form = new FormData();
      form.append("mgrNo", this.editedItem.mgrNo);
      form.append("mgrId", this.editedItem.mgrId);
      form.append("mgrPsw", this.editMgrPsw);
      form.append("mgrStatus", this.editStatus);

      axios
        .post("php/admin/updateTab1.php", form)
        .then((res) => {
          // console.log(res.data);
          if (res.data == "尚未驗證權限") {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          } else if (res.data == "更新成功") {
            Swal.fire({
              title: "更新成功!",
              icon: "success",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            }).then(() => {
              this.backendTab_1();
            });
          } else {
            Swal.fire({
              title: "修改錯誤!",
              text: "請聯絡管理員",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            }).then(() => {
              window.location.reload();
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.backendTab_1();
  },
};
</script>
