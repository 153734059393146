<template>
  <div class="container">
    <div class="row">
      <div class="gotoTop">
        <span @click="gotoTop"><i class="fa-solid fa-circle-up"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  methods: {
    gotoTop() {
      $("html,body").animate(
        {
          scrollTop: 0,
        },
        100
      );
    },
  },
};
</script>
