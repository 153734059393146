<template>
  <div @contextmenu.prevent="handleRightClick">
    <NavBar />
    <main role="main">
      <router-view />
      <!-- <RouterView /> 也可以，加這個路徑才動的了 -->
    </main>
    <FooterArea />
  </div>
</template>

<script>
//1. 先import 組件檔
import NavBar from "./components/NavBar.vue";
import FooterArea from "./components/FooterArea.vue";

//2. import組件檔進來後，放在components裡面
export default {
  name: "App",
  components: {
    NavBar,
    FooterArea,
  },
  methods: {
    handleRightClick(event) {
      //阻止右鍵點擊事件的預設行為
      event.preventDefault();
    },
  },
};
</script>
