<template>
  <div>
    <div class="edit">
      <div @click="toggleEditMode">
        <button ref="editButton" type="button" class="editBtn">
          {{ editMode ? "確認" : "編輯" }}
        </button>
      </div>
    </div>
    <b-table striped hover :items="items" :fields="fields">
      <template v-slot:cell(checked)="data">
        <b-form-checkbox
          v-model="data.item.checkbox"
          :disabled="!editMode"
          v-show="showCheckbox"
          @change="checked(data.item)"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(content)="data">
        <div class="mdContent" v-html="data.value"></div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import md from "@/markdown";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: {},
  data() {
    return {
      fields: [
        {
          key: "itemNo",
          label: "編號",
          sortable: true,
        },
        {
          key: "category",
          label: "分類",
          sortable: true,
        },
        {
          key: "topic",
          label: "項目標題",
        },
        {
          key: "content",
          label: "項目內容",
        },
        {
          key: "buildDate",
          label: "建立日期",
          sortable: true,
        },
        {
          key: "status",
          label: "狀態",
          sortable: true,
        },
        {
          key: "checked",
          label: "勾選",
        },
      ],
      editMode: false, //checkbox
      selectedItemNo: "",
      showCheckbox: false,
    };
  },
  computed: {
    ...mapState(["tab_6_2", "tab_5"]),

    //讀取項目
    items() {
      let data = this.editMode
        ? this.$store.state.tab_5
        : this.$store.state.tab_6_2;
      return Object.values(data).map((item) => ({
        itemNo: item.itemNo,
        category: item.category,
        topic: item.topic,
        content: md.render(item.content),
        buildDate: item.buildDate,
        status: this.getStatusText(item.status),
        checkbox: false, // 新增的 checkbox 狀態
      }));
    },
  },
  methods: {
    ...mapActions(["backendTab_6_2", "backendTab_5"]),

    //顯示狀態中文
    getStatusText(status) {
      return status === "0" ? "正常" : "停權";
    },

    //切換編輯模式
    toggleEditMode() {
      this.editMode = !this.editMode;
      this.showCheckbox = this.editMode; //根據編輯模式的狀態來更新 showCheckbox
      this.$refs.editButton.textContent = this.editMode ? "確認" : "編輯";

      // 如果是"確認"按鈕(!this.editMode = true)，則觸發confirmEdit(將該欄位資料送出)
      if (!this.editMode) {
        this.confirmEdit();
      }
    },

    //當勾選，觸發change事件函數checked()，將勾選的資料存進selectedItems陣列裡
    checked(item) {
      // 如果是編輯模式，處理 checkbox 變化
      if (this.editMode) {
        // row === true 表示比較this.items陣列中的每一個元素是否與被勾選的item特定元素相同
        // 相同的話，代表被勾選，checkbox狀態為true，其他的checkbox設置為false
        this.items.forEach((row) => {
          if (row.itemNo === item.itemNo) {
            row.checkbox = true;
          } else {
            row.checkbox = false;
          }
        });

        // 更新選中的項目
        // 如果item.checkbox為true，代表被勾選，item就是被勾選的特定item內容
        if (item.checkbox) {
          this.selectedItemNo = item.itemNo;
        }
      }
    },

    // "確認" 按鈕點擊事件 (加入異部請求)
    async confirmEdit() {
      if (this.selectedItemNo) {
        let form = new FormData();
        form.append("itemNo", this.selectedItemNo);
        try {
          let res = await axios.post("php/admin/updateTab6_2.php", form);
          if (res.data == "尚未驗證權限") {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          } else if (res.data === "更新成功") {
            Swal.fire({
              title: "更新成功",
              text: "請至前台確認頁面",
              icon: "success",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/");
          }
        } catch (error) {
          console.error("Error updating item:", error);
          Swal.fire({
            title: "修改錯誤!",
            text: "請聯絡管理員",
            icon: "error",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          }).then(() => {
            window.location.reload();
          });
        }
      } else {
        Swal.fire({
          title: "請選擇項目!",
          text: "沒有勾選任何項目",
          icon: "warning",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
      }
    },
  },
  watch: {
    // 監聽編輯模式的變化，重新加載數據
    editMode() {
      this.backendTab_6_2();
      this.backendTab_5();
    },
  },
  mounted() {
    this.backendTab_6_2();
  },
};
</script>
