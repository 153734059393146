<template>
  <div class="tab">
    <h3>最新文章與影片</h3>
    <!-- <div class="tab6_content mt-4">
      <b-tabs content-class="mt-4">
        <b-tab title="最新文章" active>
          <div class="edit">
            <button type="button" class="editBtn">編輯</button>
          </div>
          <div class="editContent">
            <b-table striped hover :items="items" :fields="fields"></b-table>
          </div>
        </b-tab>

        <b-tab title="最新影片">
          <div class="edit">
            <button type="button" class="editBtn">編輯</button>
          </div>
          <div class="editContent">
            <b-table striped hover :items="items" :fields="fields"></b-table>
          </div>
        </b-tab>
      </b-tabs>
    </div> -->

    <div class="tab6_content mt-3">
      <ul class="tab6_list">
        <li :class="{ on: show }" @click="(content = 'tab_6_1'), showToggle()">
          最新文章
        </li>
        <li
          :class="{ on: notShow }"
          @click="(content = 'tab_6_2'), showToggle()"
        >
          最新影片
        </li>
      </ul>

      <div class="editContent">
        <keep-alive>
          <component :is="content"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import tab_6_1 from "../../components/BackTab/Tab_6_1.vue";
import tab_6_2 from "../../components/BackTab/Tab_6_2.vue";

export default {
  data() {
    return {
      fields: [
        {
          key: "itemNo",
          label: "編號",
          sortable: true,
        },
        {
          key: "category",
          label: "分類",
          sortable: true,
        },
        {
          key: "topic",
          label: "項目標題",
        },
        {
          key: "content",
          label: "項目內容",
        },
        {
          key: "buildDate",
          label: "建立日期",
          sortable: true,
        },
        {
          key: "status",
          label: "狀態",
          sortable: true,
        },
      ],
      content: tab_6_1,
      show: true,
      notShow: false,
    };
  },
  components: {
    tab_6_1,
    tab_6_2,
  },
  computed: {
    ...mapState(["tab_6"]),

    items() {
      let newArr = [];
      for (let key in this.$store.state.tab_6) {
        let itemNo = this.$store.state.tab_6[key].itemNo;
        let category = this.$store.state.tab_6[key].category;
        let topic = this.$store.state.tab_6[key].topic;
        let content = this.$store.state.tab_6[key].content;
        let buildDate = this.$store.state.tab_6[key].buildDate;

        if (this.$store.state.tab_6[key].status == "0") {
          let status = "正常";
          newArr.push({ itemNo, category, topic, content, buildDate, status });
        } else if (this.$store.state.tab_6[key].status == "1") {
          let status = "停權";
          newArr.push({ itemNo, category, topic, content, buildDate, status });
        }
      }
      return newArr;
    },
  },
  methods: {
    ...mapActions(["backendTab_6"]),

    showToggle() {
      this.show = !this.show;
      this.notShow = !this.notShow;
    },
  },
  mounted() {
    this.backendTab_6();
  },
};
</script>
