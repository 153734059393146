<template>
  <div id="mingContentMed">
    <!-- banner-->
    <div>
      <b-card
        size="sm"
        overlay
        img-src="banner/video.jpg"
        img-alt="video"
        text-variant="white"
        title="自製橘子皮精油，改善你的睡眠品質!"
        sub-title=""
        class="custom_card_video"
      >
        <b-card-text> </b-card-text>
      </b-card>
    </div>

    <div class="row mt-4 breadcrumb">
      <ol>
        <li>
          <router-link to="/">
            <span><i class="fa-solid fa-house" style="color: #3881ff"></i></span
            >首頁</router-link
          >
        </li>
        <li></li>
        <li>影片</li>
      </ol>
    </div>

    <div class="num">
      共 <span>{{ length }}</span> 筆
    </div>

    <div class="currentPage">
      <div class="page">
        第<span> {{ currentPage }} </span>頁
      </div>
    </div>

    <b-container fluid="xl" class="p-4">
      <b-row>
        <b-col
          v-for="(item, index) in paginatedData"
          :key="index"
          class="mb-5 box"
          cols="12"
          :lg="index % 5 < 2 ? '6' : '4'"
          :md="6"
          :data-aos="'fade-down'"
          ><b-card
            :img-alt="item.topic"
            img-top
            tag="article"
            class="mb-2 mx-auto"
          >
            <div class="img-card">
              <a :href="getLink(item.itemNo)" @click="seeMore(item.itemNo)">
                <img :src="imageUrl_1()[index]" :alt="item.topic" />
              </a>
            </div>

            <h4 class="topic">
              {{ item.topic }}
            </h4>

            <b-card-text class="date">
              {{ item.buildDate.slice(0, 10) }}
            </b-card-text>

            <router-link to="/video">
              <b-card-text class="category">
                {{ item.category }}
              </b-card-text>
            </router-link>

            <b-card-text
              class="text"
              v-html="md.render(item.content, item.category)"
            ></b-card-text>

            <a
              class="seeMoreBtn"
              :href="getLink(item.itemNo)"
              @click="seeMore(item.itemNo)"
            >
              <span>看更多</span>
            </a>
          </b-card></b-col
        >

        <!-- 頁數 -->
        <div class="overflow-auto showPage">
          <div class="mt-3">
            <b-pagination-nav
              :link-gen="linkGen"
              :number-of-pages="Math.ceil(length / perPage)"
              v-model="currentPage"
              use-router
            ></b-pagination-nav>
          </div>
        </div>

        <!-- 回到最上面 -->
        <GotoTop />
      </b-row>
    </b-container>

    <!-- 顯示社群媒體 -->
    <showMedia />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import md from "@/markdown";
import showMedia from "../../components/showMedia.vue";
import GotoTop from "@/components/GotoTop.vue";
import AOS from "aos";

export default {
  data() {
    return {
      rows: null,
      currentPage: 1,
      perPage: 10,
      md: md,
      storage: sessionStorage,

      //處裡路徑
      mapping: {
        中醫文章: "medicine",
        中醫穴道: "acupuncture",
        影片: "video",
        吃喝玩樂: "life",
        好物推薦: "category1",
        好吃好玩: "category2",
        生活雜事: "category3",
      },

      title: "部落格 | 張宏銘中醫師鯨生情緣 - 影片",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          property: "og:title",
          content: this.title,
        },
        {
          property: "og:url",
          content: "https://whalemingdoctor.tw/video",
        },
        {
          property: "og:video",
          content: "https://www.youtube.com/watch?v=M_xNhtGsebI",
        },
        {
          property: "og:keywords",
          content:
            "張宏銘, 張宏銘中醫師, 張宏銘中醫師鯨生情緣, 部落格, 中醫, 中醫文章, 中醫穴道",
        },
        {
          property: "og:site_name",
          content: this.title,
        },
      ],
    };
  },
  components: {
    showMedia,
    GotoTop,
  },
  computed: {
    ...mapState(["video"]),

    ...mapGetters({
      length: "videoLength",
    }),

    //根據當前頁碼和每頁顯示的項目數計算分頁有幾頁
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.video.slice(startIndex, endIndex);
    },
  },
  methods: {
    ...mapActions(["fetchVideo"]),

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    //顯示絕對圖片路徑url
    imageUrl_1() {
      let baseUrl = location.origin;

      let images = this.paginatedData.map((item) => item.topicImage);

      let absoImageUrl = images.map((image) => `${baseUrl}/${image}`);

      return absoImageUrl;
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    //a標籤連結
    getLink(itemNo) {
      //目前網站根目錄的url
      let baseUrl = location.origin;

      //根目錄與相對路徑結合，變成完整https://開頭的url
      let absoImageUrl = `${baseUrl}/content?sn=${itemNo}`;

      return absoImageUrl;
    },

    //看內容
    seeMore(itemNo) {
      this.saveRecord(itemNo); //儲存到sessionstorage做瀏覽紀錄
    },

    saveRecord(itemNo) {
      //建立key
      if (this.storage["addItemNo"] == null) {
        this.storage["addItemNo"] = "";
      }

      //儲存value
      if (!this.storage[itemNo]) {
        //儲存資料
        this.storage["addItemNo"] += itemNo + ", ";
        // 表示已經儲存過
        this.storage[itemNo] = true;
      }
    },
  },
  mounted() {
    this.fetchVideo();

    AOS.init({
      duration: 1000,
    });

    const page = parseInt(this.$route.query.page) || 1; //先抓page。先轉整數，如果不是整數就是第一頁
    if (isNaN(page) || page < 1) {
      //如果頁數不是數字或小於1
      this.currentPage = 1; // 就預設頁數為第一頁
    } else {
      this.currentPage = page; //否則就根據當前的頁數顯示對應的內容
    }
  },
};
</script>
