<template>
  <div :key="keyForRerender">
    <header class="sticky-top">
      <nav class="navbar">
        <div class="con">
          <router-link to="/"
            ><p class="brand">部落格 | 張宏銘中醫師-鯨生情緣</p>
            <img src="/whale.png" alt="部落格 | 張宏銘中醫師-鯨生情緣" />
          </router-link>
          <div class="button-toggle" :class="{ off: show }" @click="navToggle">
            三
          </div>
          <div
            class="button-toggle close"
            :class="{ off: notShow }"
            @click="navToggle"
          >
            x
          </div>
          <ul class="navbar-menu" ref="navbarRef">
            <li class="navbar-list" @click="navToggle">
              <router-link to="/life" class="lifeLink">吃喝玩樂</router-link>
              <div class="sub-nav">
                <div class="sub-con">
                  <ul class="sub-menu">
                    <li class="sub-list">
                      <router-link to="/life/category1">好物推薦</router-link>
                    </li>

                    <li class="sub-list">
                      <router-link to="/life/category2">好吃好玩</router-link>
                    </li>

                    <li class="sub-list">
                      <router-link to="/life/category3">生活雜事</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li class="navbar-list" @click="navToggle">
              <router-link to="/medicine">中醫文章</router-link>
            </li>
            <li class="navbar-list" @click="navToggle">
              <router-link to="/acupuncture">中醫穴道</router-link>
            </li>
            <li class="navbar-list" @click="navToggle">
              <router-link to="/video">影片</router-link>
            </li>
            <li>
              <form class="searchForm" @submit.prevent="search">
                <input
                  type="text"
                  class="form-control"
                  placeholder="請輸入關鍵字"
                  v-model="keyword"
                />

                <button type="submit" @click="navToggle">
                  <i
                    class="fa-solid fa-magnifying-glass searIcon"
                    style="color: #797f8b"
                  ></i>
                </button>
              </form>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      keyForRerender: 0,
      show: false,
      notShow: true,
      keyword: "",
    };
  },
  methods: {
    initComponent() {
      this.keyword = "";
    },

    // for 991px的效果
    navToggle() {
      // 漢堡三條線與叉叉的切換
      this.show = !this.show;
      this.notShow = !this.notShow;

      //漢堡高度整個下與上個收起的切換
      this.$refs.navbarRef.classList.toggle("active");
      //body的Y軸，滾輪隱藏
      document.body.classList.toggle("fixed");
    },

    search() {
      let currentRoute = this.$route.fullPath;
      let targetRoute = `/search?keyword=${this.keyword}`;

      // 如果目前的路由已經是搜尋頁面，則不執行
      if (currentRoute !== targetRoute) {
        this.$router.push({
          name: "search",
          query: { keyword: this.keyword },
        });

        this.keyword = "";

        this.keyForRerender += 1; // 強制重新渲染(刪掉其實也可以)
      }
    },
  },
  mounted() {
    this.initComponent(); //keyword先清空(刪掉其實也可以)
  },
};
</script>
