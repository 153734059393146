<template>
  <div class="backendArea">
    <div class="backend">
      <div class="back-title">
        <div class="back-title_box">
          <div>後台管理系統</div>
          <div class="logout" @click="logout">登出</div>
        </div>
      </div>

      <div class="back-main">
        <div class="row">
          <div class="back-main-left col-2">
            <div class="back-man">
              管理員: <span>{{ mgrId }}</span>
            </div>

            <div
              v-for="(tab, index) in tabs"
              :key="index"
              class="back-menu"
              :class="{ on: currentTab === tab.component }"
              @click="changeTab(tab.component)"
            >
              {{ tab.title }}
            </div>

            <router-link to="/addStep1" class="back-menu-add"
              >新增文章</router-link
            >
          </div>

          <div class="back-main-right col-10">
            <keep-alive>
              <component :is="currentTab"></component>
            </keep-alive>
          </div>
          <GotoTop />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import tab_1 from "../../components/BackTab/Tab_1.vue";
import tab_2 from "../../components/BackTab/Tab_2.vue";
import tab_3 from "../../components/BackTab/Tab_3.vue";
import tab_4 from "../../components/BackTab/Tab_4.vue";
import tab_5 from "../../components/BackTab/Tab_5.vue";
import tab_6 from "../../components/BackTab/Tab_6.vue";
import tab_7 from "../../components/BackTab/Tab_7.vue";
import tab_8 from "../../components/BackTab/Tab_8.vue";
import tab_9 from "../../components/BackTab/Tab_9.vue";
import tab_10 from "../../components/BackTab/Tab_10.vue";
import GotoTop from "../../components/GotoTop.vue";

export default {
  components: {
    tab_1,
    tab_2,
    tab_3,
    tab_4,
    tab_5,
    tab_6,
    tab_7,
    tab_8,
    tab_9,
    tab_10,
    GotoTop,
  },
  data() {
    return {
      mgrId: "",
      tabs: [
        { title: "訪客人數", component: "tab_8" },
        { title: "管理員資訊", component: "tab_1" },
        { title: "生活文章管理", component: "tab_2" },
        { title: "中醫文章管理", component: "tab_3" },
        { title: "中醫穴道管理", component: "tab_4" },
        { title: "影片管理", component: "tab_5" },
        { title: "最新文章與影片", component: "tab_6" },
        { title: "首頁關鍵字管理", component: "tab_7" },
        { title: "生成xml檔", component: "tab_9" },
        { title: "meta關鍵字管理", component: "tab_10" },
      ],
      currentTab: "tab_8",
    };
  },
  methods: {
    //每次更新網頁都會檢查管理員權限
    mgrCheck() {
      axios
        .post("php/checkMgrRight.php")
        .then((res) => {
          let info = res.data.mgrId;
          return info;
        })
        .then((res) => {
          if (res) {
            this.mgrId = res;
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          }
        });
    },

    changeTab(componentName) {
      this.currentTab = componentName;
    },

    //登出
    logout() {
      axios.post("php/logOut.php").then((res) => {
        // console.log(res.data);
        if (res.data == "已登出後台!") {
          this.mgrId = "";
          Swal.fire({
            title: res.data,
            icon: "success",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          this.$router.push("/logIn");
        }
      });
    },
  },
  computed: {},
  mounted() {
    this.mgrCheck();
  },
};
</script>
