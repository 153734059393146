<template>
  <div>
    <button @click="generateXML">生成並下載 XML</button>
  </div>
</template>

<script>
export default {
  methods: {
    generateXML() {
      this.$store.dispatch("getXML");
    },
  },
};
</script>
