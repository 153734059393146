<template>
  <div class="tab">
    <h3>首頁關鍵字管理</h3>
    <div class="list">
      <div class="num">
        共 <span>{{ length }}</span> 筆
      </div>

      <!-- 新增表單燈箱 -->
      <div class="edit">
        <b-button v-b-modal.modal-prevent-closing>新增</b-button>

        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title="新增關鍵字"
          cancel-title="取消"
          ok-title="確認"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <form ref="form" @submit.prevent="handleSubmit">
            <b-form-group
              label="關鍵字"
              label-for="name-input"
              :invalid-feedback="invalidMsg"
              :state="nameState"
            >
              <b-form-input
                id="name-input"
                v-model="name"
                :state="nameState"
                @input="checkValidity"
                required
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
      </div>
    </div>

    <div class="tab7_content">
      <b-table striped hover :items="items" :fields="fields" class="table7">
        <!-- 狀態 -->
        <template v-slot:cell(status)="data">
          <span v-if="!isEditing || data.item !== editedItem">{{
            data.item.status
          }}</span>
          <input v-else type="text" v-model="editStatus" />
        </template>

        <!-- 編輯按鈕 -->
        <template v-slot:cell(edit)="data">
          <button class="editBtn" @click="toggleEdit(data.item)">
            {{ isEditing && data.item === editedItem ? "確認" : "編輯" }}
          </button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      fields: [
        {
          key: "keywordNo",
          label: "項目編號",
          sortable: true,
        },
        {
          key: "keywordName",
          label: "關鍵字",
          sortable: true,
        },
        {
          key: "status",
          label: "狀態(0:上架 / 1:下架)",
          sortable: true,
        },
        {
          key: "edit",
          label: "編輯",
        },
      ],
      editStatus: null, //更新的狀態
      isEditing: false,
      editedItem: null,

      //新增
      name: "",
      nameState: null,
    };
  },
  components: {},
  computed: {
    ...mapState(["tab_7"]),
    ...mapGetters({
      length: "tab7Length",
    }),

    items() {
      return this.$store.state.tab_7.map((item) => ({
        keywordNo: item.keywordNo,
        keywordName: item.keywordName,
        status: item.status,
      }));
    },

    //驗證無效
    invalidMsg() {
      let trimName = this.name.trim();
      if (!trimName) {
        return "不可空白";
      } else if (this.validation) {
        return "關鍵字重複";
      } else {
        return null;
      }
    },

    //驗證重複名字
    validation() {
      let trimName = this.name.trim();
      return this.items.some((item) => item.keywordName === trimName);
    },
  },
  methods: {
    ...mapActions(["backendTab_7"]),

    toggleEdit(item) {
      if (this.isEditing && item === this.editedItem) {
        // 確認編輯完成
        this.isEditing = false;

        //儲存更新的內容
        this.saveEditItem();
      } else {
        // 進入編輯模式
        this.isEditing = true;
        this.editStatus = item.status;
        this.editedItem = item;
      }
    },

    //編輯
    saveEditItem() {
      // console.log(this.editStatus);
      let form = new FormData();
      form.append("keywordNo", this.editedItem.keywordNo);
      form.append("status", this.editStatus);

      axios
        .post("php/admin/updateTab7.php", form)
        .then((res) => {
          // console.log(res.data);
          if (res.data == "更新成功") {
            Swal.fire({
              title: "更新成功!",
              icon: "success",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            }).then(() => {
              this.backendTab_7();
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    //驗證表單
    checkValidity() {
      let trimName = this.name.trim();
      if (trimName === "") {
        this.nameState = false;
        return false;
      } else if (this.validation) {
        this.nameState = false;
        return false;
      } else {
        this.nameState = true;
        return true;
      }
    },

    //檢查表單有效性
    checkFormValidity() {
      let trimName = this.name.trim();
      if (trimName === "") {
        this.nameState = false;
        return false;
      } else if (this.validation) {
        this.nameState = false;
        return false;
      } else {
        this.nameState = true;
        return true;
      }
    },

    //取消的按鈕
    resetModal() {
      this.name = "";
      this.nameState = null;
    },

    //表單送出
    handleSubmit() {
      if (this.nameState) {
        let trimName = this.name.trim();
        let formData = new FormData();
        formData.append("keywordName", trimName);

        axios
          .post("php/admin/Tab_7_add.php", formData)
          .then((res) => {
            if (res.data == "新增成功") {
              Swal.fire({
                title: "新增成功!",
                icon: "success",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              }).then(() => {
                this.backendTab_7();
              });
            } else if (res.data == "關鍵字已經存在") {
              Swal.fire({
                title: "關鍵字已經存在",
                icon: "error",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              }).then(() => {
                this.backendTab_7();
              });
            }
          })
          .catch((error) => {
            console.log(error.response);
          });

        //表單關閉
        this.$nextTick(() => {
          this.$bvModal.hide("modal-prevent-closing");
        });
      }
    },

    //確認的按鈕
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      if (this.checkFormValidity()) {
        this.handleSubmit();
      }
    },
  },
  mounted() {
    this.backendTab_7();
  },
};
</script>
