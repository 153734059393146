import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";

//用console.log檢查一下
// const md_1 = new MarkdownIt();
// console.lo(md_1);
// console.log(md_1.render("# markdown-it rulezz!")); //得到<h1>markdown-it rulezz!</h1

// const md = new MarkdownIt({
//   html: true,
//   linkfy: true,
//   typographer: true,
//   breaks: true,
//   highlight: function (str, lang) {
//     console.log("str->", str, "lang->", lang);
//   },
// });

//測試emoji
// const md2 = new MarkdownIt().use(require("markdown-it-emoji"));
// console.log("使用小圖", md2.render("8-)"));

const md = new MarkdownIt({
  html: true, //markdown文字中可以放入html tag
  linkify: true, // 將 URL 轉換為超連結，在新的分頁來顯示
  typographer: true,
  breaks: true, //遇到"\n"會轉成 <br>
  // langPrefix: "language-", //css language prefix
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (__) {
        console.log("123");
      }
    }
    return "";
  },
})
  .use(require("markdown-it-emoji"))
  .use(require("markdown-it-container"), "spoiler", {
    validate: function (params) {
      return params.trim().match(/^spoiler\s+(.*)$/);
    },

    render: function (tokens, idx) {
      var m = tokens[idx].info.trim().match(/^spoiler\s+(.*)$/);

      if (tokens[idx].nesting === 1) {
        // opening tag
        return (
          "<details><summary>" + md.utils.escapeHtml(m[1]) + "</summary>\n"
        );
      } else {
        // closing tag
        return "</details>\n";
      }
    },
  })
  .use(require("markdown-it-task-lists"))
  // console.log(md.render("::: spoiler click me\n*content*\n:::\n"));
  //output(輸出結果)
  //<details><summary>click me</summary>
  //<p><em>content</em></p>
  //</details>
  .use(require("markdown-it-abbr"));
// console.log(md.render("*[HTML]: Hyper Text Markup Language\n The HTML specification"))

//加入highlight.js - 編寫程式會有黑色底圖，加上顏色
//<pre><code>格式</code></pre>
md.renderer.rules.fence = function (tokens, idx, options, env, instance) {
  var token = tokens[idx];
  var lang = token.info.trim();
  var code = token.content.trim();

  if (lang) {
    try {
      return (
        '<pre class="hljs"><code>' +
        hljs.highlight(lang, code).value +
        "</code></pre>"
      );
    } catch (__) {
      console.log("Error highlighting code");
    }
  }

  return (
    '<pre class="hljs"><code>' +
    instance.utils.escapeHtml(code) +
    "</code></pre>"
  );
};

// 設置 link_open renderer 在新分頁中打開連結 | a標籤會添加target="_blank"
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const token = tokens[idx];
  const targetIndex = token.attrIndex("target");

  if (targetIndex === -1) {
    token.attrPush(["target", "_blank"]);
  } else {
    token.attrs[targetIndex][1] = "_blank";
  }

  return self.renderToken(tokens, idx, options);
};

export default md;
