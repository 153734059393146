<template>
  <div id="homePage">
    <BannerArea />
    <div class="content">
      <!-- 左半部 -->
      <div class="col-12 col-lg-9 homeLeft">
        <!-- 介紹 -->
        <div class="intro">
          <h2>大家好! 我是張宏銘中醫師</h2>
          <h2>這個部落格是集結臉書</h2>
          <a :href="fb" target="張宏銘中醫師鯨生情緣FB"
            ><h2>#張宏銘中醫師鯨生情緣</h2></a
          >
          <h2>的一些文章以及生活雜記分享</h2>
          <img :src="introImg" alt="bd" />
        </div>

        <!-- 最新文章 -->
        <div class="homeLeftArea">
          <div class="leftTitle">
            <div class="line_1"></div>
            <h4>最新文章</h4>
            <div class="line_2"></div>
          </div>

          <b-col
            v-for="(item, index) in tab_6_1"
            :key="index"
            class="mb-4 mt-4 leftArea1"
            cols="12"
            ><b-card
              :alt="item.topic"
              img-top
              tag="article"
              class="mb-2 mx-auto"
            >
              <div class="img-card">
                <a :href="getLink(item.itemNo)" @click="seeMore(item.itemNo)">
                  <img :src="imageUrl_1()" :alt="item.topic" />
                </a>
              </div>

              <div class="topic">
                <span class="new">new!new!new!</span>
                <h4>{{ item.topic }}</h4>
              </div>

              <b-card-text class="date">
                {{ item.buildDate.slice(0, 10) }}
              </b-card-text>

              <router-link
                v-if="mapping[item.category]"
                :to="mapping[item.category]"
              >
                <b-card-text class="category">
                  {{ item.category }}
                </b-card-text>
              </router-link>

              <b-card-text
                class="text"
                v-html="md.render(process(item.content))"
              ></b-card-text>

              <a
                class="seeMoreBtn"
                :href="getLink(item.itemNo)"
                @click="seeMore(item.itemNo)"
              >
                <span>看更多</span>
              </a>
            </b-card></b-col
          >
        </div>

        <!-- 網站目錄 -->
        <SiteMap />

        <!-- 精選文章 -->
        <div class="homeLeftArea">
          <div class="leftTitle">
            <div class="line_1"></div>
            <h4>精選文章</h4>
            <div class="line_2"></div>
          </div>

          <div class="leftArea2 mt-4">
            <div
              class="cardArea"
              v-for="(item, index) in homePage_1"
              :key="index"
              :data-aos="index % 2 === 0 ? 'flip-left' : 'flip-right'"
            >
              <!-- v-if決定img要在左還是右邊 -->
              <div class="imgArea" v-if="index % 2 === 0">
                <a :href="getLink(item.itemNo)" @click="seeMore(item.itemNo)">
                  <img :src="imageUrl_2()[index]" :alt="item.topic" />
                </a>
              </div>

              <div class="contentArea">
                <div class="content" v-html="md.render(item.content)"></div>
                <div class="seeMore">
                  <a
                    class="seeMoreBtn"
                    :href="getLink(item.itemNo)"
                    @click="seeMore(item.itemNo)"
                  >
                    <span>看更多</span>
                  </a>
                </div>
              </div>

              <div class="imgArea" v-if="index % 2 === 1">
                <a :href="getLink(item.itemNo)" @click="seeMore(item.itemNo)">
                  <img :src="imageUrl_2()[index]" :alt="item.topic" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- 吃喝玩樂 -->
        <div class="homeLeftArea">
          <div class="leftTitle">
            <div class="line_1"></div>
            <h4>吃喝玩樂</h4>
            <div class="line_2"></div>
          </div>

          <div class="leftArea3 mt-4 m-3">
            <div
              class="cardArea row"
              v-for="(item, index) in home_3"
              :key="index"
              data-aos="fade-up"
            >
              <a :href="getLink(item.itemNo)" @click="seeMore(item.itemNo)">
                <b-card :alt="item.category" img-left class="mb-3">
                  <div class="imgArea">
                    <img :src="imageUrl_3()[index]" :alt="item.topic" />
                  </div>
                  <b-card-text>
                    <h4>{{ item.topic }}</h4>
                    <div class="text" v-html="md.render(item.content)"></div>
                  </b-card-text>
                </b-card>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- 右半部 -->
      <div class="col-12 col-lg-3 homeRight">
        <div class="homeRightArea">
          <div class="rightTitle"><h5>熱門影片</h5></div>
          <div class="video" v-for="(item, index) in tab_6_2" :key="index">
            <div v-html="item.videoLink"></div>
          </div>
        </div>

        <div class="homeRightArea">
          <!-- Facebook Page Plugin  -->
          <div class="rightTitle"><h5>粉絲專頁</h5></div>
          <div class="fbArea">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSuperWhaleMing%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              style="border: none; overflow: hidden"
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>

        <div class="homeRightArea">
          <div class="rightTitle"><h5>熱門標籤</h5></div>
          <div class="keyArea">
            <div
              class="keywordName"
              @click="search(item.keywordName)"
              v-for="(item, index) in home_2"
              :key="index"
            >
              #{{ item.keywordName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 回到最上面 -->
    <GotoTop />

    <!-- 顯示社群媒體 -->
    <showMedia />
  </div>
</template>

<script>
import BannerArea from "../../components/BannerArea.vue";
import SiteMap from "../../components/SiteMap.vue";
import md from "@/markdown";
import showMedia from "../../components/showMedia.vue";
import GotoTop from "../../components/GotoTop.vue";
import { mapActions, mapState } from "vuex";
import AOS from "aos";

export default {
  components: {
    BannerArea,
    SiteMap,
    showMedia,
    GotoTop,
  },
  data() {
    return {
      md: md,
      storage: sessionStorage,
      introImg: "banner/bd_1.jpg",
      fb: "https://www.facebook.com/SuperWhaleMing/",

      //處裡路徑
      mapping: {
        中醫文章: "medicine",
        中醫穴道: "acupuncture",
        影片: "video",
        吃喝玩樂: "life",
      },

      title: "部落格 | 張宏銘中醫師鯨生情緣",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          property: "og:title",
          content: this.title,
        },
        {
          property: "og:url",
          content: "https://whalemingdoctor.tw",
        },
        {
          property: "og:image",
          content: this.indexImagUrl(),
        },
        {
          property: "og:keywords",
          content:
            "張宏銘, 張宏銘中醫師, 張宏銘中醫師鯨生情緣, 部落格, 中醫, 中醫文章, 中醫穴道",
        },
        {
          property: "og:site_name",
          content: this.title,
        },
      ],
    };
  },
  computed: {
    ...mapState(["tab_6_1", "tab_6_2", "homePage_1", "home_2", "home_3"]),
  },
  methods: {
    ...mapActions([
      "updateVisitor",
      "backendTab_6_1",
      "backendTab_6_2",
      "fetchHome_1",
      "fetchHome_2",
      "fetchHome_3",
    ]),

    //for OG meta tag (絕對路徑圖片url) | 首頁的大圖
    indexImagUrl() {
      //目前網站根目錄的url
      let baseUrl = location.origin;

      //根目錄與相對路徑結合，變成完整https://開頭的url
      let absoImageUrl = baseUrl + "/" + "banner/banner_1.jpg";

      return absoImageUrl;
    },

    //最新文章的image絕對路徑
    imageUrl_1() {
      let baseUrl = location.origin;

      let images = this.tab_6_1.map((item) => item.topicImage);

      let absoImageUrl = images.map((image) => `${baseUrl}/${image}`);

      return absoImageUrl;
    },

    //精選文章的image絕對路徑
    imageUrl_2() {
      let baseUrl = location.origin;

      let images = this.homePage_1.map((item) => item.topicImage);

      let absoImageUrl = images.map((image) => `${baseUrl}/${image}`);

      return absoImageUrl;
    },

    //吃喝玩樂的image絕對路徑
    imageUrl_3() {
      let baseUrl = location.origin;

      let images = this.home_3.map((item) => item.topicImage);

      let absoImageUrl = images.map((image) => `${baseUrl}/${image}`);

      return absoImageUrl;
    },

    //最新文章(把img都去除再渲染，避免內容的圖片出現在首頁的最新文章區塊)
    process(content) {
      //找![...](...)
      let imgRegex = /!\[(.*?)\]\((.*?)\)/g;

      //將圖片替換為空字串
      let process = content.replace(imgRegex, "");

      return process;
    },

    //a標籤連結
    getLink(itemNo) {
      //目前網站根目錄的url
      let baseUrl = location.origin;

      //根目錄與相對路徑結合，變成完整https://開頭的url
      let absoImageUrl = `${baseUrl}/content?sn=${itemNo}`;

      return absoImageUrl;
    },

    //看內容
    seeMore(info) {
      this.saveRecord(info); //儲存到sessionstorage做瀏覽紀錄
    },

    saveRecord(info) {
      //建立key
      if (this.storage["addItemNo"] == null) {
        this.storage["addItemNo"] = "";
      }

      //儲存value
      if (!this.storage[info]) {
        //儲存資料
        this.storage["addItemNo"] += info + ", ";
        // 表示已經儲存過
        this.storage[info] = true;
      }
    },

    //熱門關鍵字
    search(keywordName) {
      let currentRoute = this.$route.fullPath;
      let targetRoute = `/search?keyword=${keywordName}`;

      // 如果目前的路由已經是搜尋頁面，則不執行
      if (currentRoute !== targetRoute) {
        this.$router.push({
          name: "search",
          query: { keyword: keywordName },
        });
      }
    },

    startAOS() {
      //啟動AOS
      AOS.refresh();
    },
  },
  mounted() {
    //更新訪客人數(每次進來首頁就到後台訪客人數+1)
    this.updateVisitor();

    //抓最新文章
    this.backendTab_6_1();

    //抓熱門影片
    this.backendTab_6_2();

    //抓首頁資料-精選文章
    this.fetchHome_1();

    //抓首頁資料-關鍵字
    this.fetchHome_2();

    //抓首頁資料-吃喝玩樂
    this.fetchHome_3();

    AOS.init({
      duration: 1000,
    });
  },
};
</script>
