<template>
  <div id="mindAdd">
    <div class="back-title">
      <div class="back-title_box">
        <div>後台管理系統</div>
        <div class="logout" @click="logout">登出</div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <div class="add_manager">
          管理員: <span>{{ mgrId }}</span>
        </div>
      </div>
      <div class="row mt-4 breadcrumb">
        <ol>
          <li>
            <router-link to="/">
              <span
                ><i class="fa-solid fa-house" style="color: #3881ff"></i></span
              >首頁</router-link
            >
          </li>
          <li></li>
          <li><router-link to="adminPanel">管理頁面</router-link></li>
          <li></li>
          <li>新增</li>
        </ol>
      </div>
      <div class="row mt-4 step">
        <div class="d-flex">
          <div class="boxCircle"><div class="circle -on">1</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle -on">2</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle -on">3</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle -on">4</div></div>
        </div>
      </div>
      <div class="row mt-4 stepText">
        <div class="text">填寫文章資訊</div>
        <div class="text">上傳照片</div>
        <div class="text">編輯及預覽</div>
        <div class="text -on">完成!</div>
      </div>
      <div class="row mt-5">
        <b-icon icon="check-circle" font-scale="7" variant="success"></b-icon>
      </div>
      <div class="row mt-4 top">
        <GotoTop />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import GotoTop from "../../components/GotoTop.vue";

export default {
  data() {
    return {
      mgrId: "",
    };
  },
  components: {
    GotoTop,
  },
  computed: {},
  methods: {
    //每次更新網頁都會檢查管理員權限
    mgrCheck() {
      axios
        .post("php/checkMgrRight.php")
        .then((res) => {
          let info = res.data.mgrId;
          return info;
        })
        .then((res) => {
          if (res) {
            this.mgrId = res;
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          }
        });
    },

    //檢查步驟(延遲2.5秒再執行)
    addCheckStep() {
      setTimeout(() => {
        axios
          .post("php/add/addCheckStep.php")
          .then((res) => {
            if (res.data == "回到步驟1") {
              Swal.fire({
                title: "請從步驟1開始!",
                icon: "warning",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              });
              this.$router.push("/addStep1");
            } else if (res.data == "回到步驟2") {
              Swal.fire({
                title: "請從步驟2開始!",
                icon: "warning",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              });
              this.$router.push("/addStep2");
            } else if (res.data == "回到步驟3") {
              Swal.fire({
                title: "請從步驟3開始!",
                icon: "warning",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              });
              this.$router.push("/addStep3");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }, 2500);
    },

    //登出
    logout() {
      axios.post("php/logOut.php").then((res) => {
        // console.log(res.data);
        if (res.data == "已登出後台!") {
          this.mgrId = "";
          Swal.fire({
            title: res.data,
            icon: "success",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          this.$router.push("/logIn");
        }
      });
    },
  },

  mounted() {
    this.mgrCheck();
    this.addCheckStep(); //檢查步驟
  },
};
</script>
