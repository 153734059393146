<template>
  <div class="tab">
    <h3>訪客人數</h3>

    <!-- <div>{{ tab_8 }}</div> -->

    <div class="tab8_content">
      <div class="total">
        總瀏覽次數：<span>{{ tab_8.totalCount }}</span>
      </div>

      <div class="historyChart">
        <h5>歷史月份趨勢變化圖</h5>
        <canvas id="historyChart"></canvas>
      </div>

      <div v-for="(year, index) in uniqueYears" :key="index" class="yearChart">
        <h5>{{ year }}年分析圖</h5>
        <canvas :id="'showChart_' + year"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Chart } from "chart.js/auto";
export default {
  data() {
    return {
      uniqueYears: [],
      charts: {}, //儲存圖表資料
    };
  },
  computed: {
    ...mapState(["tab_8"]),
  },
  methods: {
    ...mapActions(["backendTab_8"]),

    //歷年訪客趨勢
    historyChart() {
      //開畫布
      const ctx = document.getElementById("historyChart").getContext("2d");

      //物件轉陣列
      let tab_8Array = Object.values(this.tab_8);

      //排除totalCount
      let tab_8Data = tab_8Array
        .filter((item) => item.visitorNo !== "totalCount")
        .filter((item) => item.visitorYear !== undefined);

      //設定圖表資料
      let labels = tab_8Data.map(
        (item) => item.visitorYear + item.visitorMonth
      );
      let datasets = [
        {
          label: "歷史趨勢變化圖",
          data: tab_8Data.map((item) => item.visitorCount),
          type: "line",
          backgroundColor: "rgba(0,123,255, 0.5)",
          borderColor: "rgba(0,123,255, 1)",
          borderWidth: 1.5,
          pointStyle: "circle",
          pointRadius: 8,
          pointHoverRadius: 10,
        },
      ];

      let data = {
        labels: labels,
        datasets: datasets,
      };

      //設定圖表選項
      let options = {
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                size: 14,
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                size: 12,
              },
            },
          },
        },
      };

      //顯示圖
      new Chart(ctx, {
        type: "line",
        data: data,
        options: options,
      });
    },

    //各個年份訪客圖表
    //async異步操作，先等待this.backendTab_8()完成，再操作visitorChart
    async visitorChart(year) {
      // 確保 backendTab_8 完成
      await this.backendTab_8();

      // 取得 canvas 元素
      const ctx = document.getElementById("showChart_" + year).getContext("2d");

      //tab_8是物件，要物件轉陣列
      let tab_8Array = Object.values(this.tab_8);

      const tab_8Data = tab_8Array.filter(
        (item) => item.visitorYear === year && item.visitorNo !== "totalCount"
      );

      const labels = tab_8Data.map((item) => item.visitorMonth + "月");

      const datasets = [
        {
          label: `${year}年網站瀏覽次數分析`,
          data: tab_8Data.map((item) => item.visitorCount),
          type: "bar",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
      ];

      const data = {
        labels: labels,
        datasets: datasets,
      };

      const options = {
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                size: 17,
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                size: 12,
              },
            },
          },
        },
      };

      // 判斷是否已經存在該年份的圖表實例，若存在則先銷毀再重新創建
      if (this.charts[year]) {
        this.charts[year].destroy();
      }

      // 創建圖表實例並存儲在 charts 物件中
      this.charts[year] = new Chart(ctx, {
        type: "bar",
        data: data,
        options: options,
      });
    },
  },
  async mounted() {
    // 確保 backendTab_8 完成，再進行其他動作
    await this.$store.dispatch("backendTab_8");

    //歷年趨勢變化
    this.historyChart();

    // 初始化 uniqueYears
    this.uniqueYears = [
      ...new Set(
        Object.values(this.tab_8)
          .filter((item) => item.visitorYear !== undefined)
          .map((item) => item.visitorYear)
      ),
    ];

    // 初始呼叫一次訪客圖表，假設 uniqueYears 不為空
    if (this.uniqueYears.length > 0) {
      this.uniqueYears.forEach((year, index) => {
        this.visitorChart(year, index);
      });
    }
  },
};
</script>
