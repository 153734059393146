import Vue from "vue";
import VueRouter from "vue-router";
import homePage from "../views/frontPage/homePage.vue";
import mingContentLife from "../views/frontPage/mingContentLife.vue";
import mingContentLife_1 from "../views/frontPage/mingContentLife_1.vue";
import mingContentLife_2 from "../views/frontPage/mingContentLife_2.vue";
import mingContentLife_3 from "../views/frontPage/mingContentLife_3.vue";
import mingContentAcu from "../views/frontPage/mingContentAcu.vue";
import mingContentMed from "../views/frontPage/mingContentMed.vue";
import mingContent from "../views/frontPage/mingContent.vue";
import mingContentVideo from "../views/frontPage/mingContentVideo.vue";
import mingSearch from "../views/frontPage/mingSearch";
import adminPanel from "../views/admin/adminPanel";
import adminSignIn from "../views/admin/adminSignIn";
import mingAddStep_1 from "../views/add/mingAddStep_1";
import mingAddStep_2 from "../views/add/mingAddStep_2";
import mingAddStep_3 from "../views/add/mingAddStep_3";
import mingAddStep_4 from "../views/add/mingAddStep_4";
import testPage from "../views/frontPage/testPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "部落格 | 張宏銘中醫師鯨生情緣",
    component: homePage,
  },
  {
    path: "/addStep1",
    name: "addStep1",
    component: mingAddStep_1,
    // component: () => import("../views/mingAddStep_1.vue"),
  },
  {
    path: "/addStep2",
    name: "addStep2",
    component: mingAddStep_2,
  },
  {
    path: "/addStep3",
    name: "addStep3",
    component: mingAddStep_3,
  },
  {
    path: "/addStep4",
    name: "addStep4",
    component: mingAddStep_4,
  },
  {
    path: "/life",
    name: "mingContentLife",
    component: mingContentLife,
  },
  {
    path: "/life/category1",
    name: "mingContentLife_1",
    component: mingContentLife_1,
  },
  {
    path: "/life/category2",
    name: "mingContentLife_2",
    component: mingContentLife_2,
  },
  {
    path: "/life/category3",
    name: "mingContentLife_3",
    component: mingContentLife_3,
  },
  {
    path: "/medicine",
    name: "mingContentMed",
    component: mingContentMed,
    // props: (route) => ({ page: route.query.page }),
  },
  {
    path: "/acupuncture",
    name: "mingContentAcu",
    component: mingContentAcu,
  },
  {
    path: "/video",
    name: "mingContentVideo",
    component: mingContentVideo,
  },
  {
    path: "/search",
    name: "search",
    component: mingSearch,
    props: (route) => ({ keyword: route.query.keyword }),
  },
  {
    path: "/content",
    name: "mingContent",
    component: mingContent,
    props: (route) => ({ sn: route.query.sn }),
  },
  {
    path: "/adminPanel",
    name: "adminPanel",
    component: adminPanel,
  },
  {
    path: "/logIn",
    name: "adminSignIn",
    component: adminSignIn,
  },
  {
    path: "/testPage",
    name: "testPage",
    component: testPage,
  },
  // {
  //   path: "/Tab_1",
  //   name: "tab_1",
  //   component: Tab_1,
  //   meta: {
  //     keepAlive: true,
  //   },
  // },
];

const router = new VueRouter({
  mode: "history", //沒加這行路徑會有#(hash)
  routes,
  scrollBehavior() {
    // console.log("123");
    return { x: 0, y: 0 };
  },
});

export default router;
