<template>
  <div id="sitemap">
    <div class="mapTitle">
      <h3>網站目錄</h3>
      <div class="mapOnpen" @click="mapToggle">
        {{ siteMode ? "隱藏目錄" : "展開目錄" }}
      </div>
    </div>

    <!-- <ol class="mapArea" :class="{ off: close }">
      <li class="mapCategory">
        <div class="mapCategoryTitle">
          <h5>好吃好玩</h5>
          <span
            @click="toggleCategory_1"
            :class="{ 'turn-up': isOpen1, 'turn-down': !isOpen1 }"
            ><i class="fa-solid fa-sort-down"></i
          ></span>
        </div>

        <ul :class="{ 'slide-in': isOpen1, 'slide-out': !isOpen1 }">
          <li
            v-for="(item, index) in life_all"
            :key="index"
            class="mapTopic"
            @click="seeMore(item.itemNo)"
          >
            {{ item.topic }}
          </li>
        </ul>
      </li>
      <li class="mapCategory">
        <div class="mapCategoryTitle">
          <h5>中醫文章</h5>
          <span
            @click="toggleCategory_2"
            :class="{ 'turn-up': isOpen2, 'turn-down': !isOpen2 }"
            ><i class="fa-solid fa-sort-down"></i
          ></span>
        </div>

        <ul :class="{ 'slide-in': isOpen2, 'slide-out': !isOpen2 }">
          <li
            v-for="(item, index) in med"
            :key="index"
            class="mapTopic"
            @click="seeMore(item.itemNo)"
          >
            {{ item.topic }}
          </li>
        </ul>
      </li>
      <li class="mapCategory">
        <div class="mapCategoryTitle">
          <h5>中醫穴道</h5>
          <span
            @click="toggleCategory_3"
            :class="{ 'turn-up': isOpen3, 'turn-down': !isOpen3 }"
            ><i class="fa-solid fa-sort-down"></i
          ></span>
        </div>

        <ul :class="{ 'slide-in': isOpen3, 'slide-out': !isOpen3 }">
          <li
            v-for="(item, index) in acu"
            :key="index"
            class="mapTopic"
            @click="seeMore(item.itemNo)"
          >
            {{ item.topic }}
          </li>
        </ul>
      </li>
      <li class="mapCategory">
        <div class="mapCategoryTitle">
          <h5>影片</h5>
          <span
            @click="toggleCategory_4"
            :class="{ 'turn-up': isOpen4, 'turn-down': !isOpen4 }"
            ><i class="fa-solid fa-sort-down"></i
          ></span>
        </div>

        <ul :class="{ 'slide-in': isOpen4, 'slide-out': !isOpen4 }">
          <li
            v-for="(item, index) in video"
            :key="index"
            class="mapTopic"
            @click="seeMore(item.itemNo)"
          >
            {{ item.topic }}
          </li>
        </ul>
      </li>
    </ol> -->

    <ol class="mapArea" :class="{ off: close }">
      <li
        class="mapCategory"
        v-for="(category, index) in categories"
        :key="index"
      >
        <div class="mapCategoryTitle">
          <h5>{{ category.name }}</h5>
          <span
            @click="toggleCategory(index)"
            :class="{ 'turn-up': isOpen[index], 'turn-down': !isOpen[index] }"
            ><i class="fa-solid fa-sort-down"></i
          ></span>
        </div>

        <ul :class="{ 'slide-in': isOpen[index], 'slide-out': !isOpen[index] }">
          <li
            v-for="(item, itemIndex) in getCategoryItems(category.items, index)"
            :key="itemIndex"
            class="mapTopic"
            @click="seeMore(item.itemNo)"
          >
            {{ item.topic }}
          </li>
        </ul>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    //如果沒東西也要加" return {}"進行初始化，確保 data 不是 undefined。
    return {
      close: false,
      siteMode: true,
      isOpen: [true, false, false, false],
      categories: [
        { name: "好吃好玩", items: "life_all" },
        { name: "中醫文章", items: "med" },
        { name: "中醫穴道", items: "acu" },
        { name: "影片", items: "video" },
      ],
      storage: sessionStorage,
    };
  },
  methods: {
    ...mapActions(["fetchLife", "fetchMed", "fetchAcu", "fetchVideo"]),

    //目錄收放
    mapToggle() {
      this.close = !this.close;
      this.siteMode = !this.siteMode;
    },

    //目錄清單toggle
    toggleCategory(index) {
      this.$set(this.isOpen, index, !this.isOpen[index]);
    },

    //獲取mapState的內容
    getCategoryItems(categoryKey, index) {
      //透過index依序取得categories的items值
      const stateKey = this.categories[index].items;

      //最後再透過this呼叫mapState
      return this[stateKey];
    },

    //看內容
    seeMore(info) {
      let itemNo = info;
      this.$router.push({ name: "mingContent", query: { sn: itemNo } });

      this.saveRecord(info); //儲存到sessionstorage做瀏覽紀錄
    },

    //近期瀏覽
    saveRecord(info) {
      //建立key
      if (this.storage["addItemNo"] == null) {
        this.storage["addItemNo"] = "";
      }

      //儲存value
      if (!this.storage[info]) {
        //儲存資料
        this.storage["addItemNo"] += info + ", ";
        // 表示已經儲存過
        this.storage[info] = true;
      }
    },
  },
  computed: {
    ...mapState(["life_all", "med", "acu", "video"]),
  },
  mounted() {
    this.fetchLife();
    this.fetchMed();
    this.fetchAcu();
    this.fetchVideo();
  },
};
</script>
