<template>
  <div id="mindAdd">
    <!-- 跳轉畫面 -->
    <div class="loading" v-if="loading"></div>
    <div class="back-title">
      <div class="back-title_box">
        <div>後台管理系統</div>
        <div class="logout" @click="logout">登出</div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <div class="add_manager">
          管理員: <span>{{ mgrId }}</span>
        </div>
      </div>
      <div class="row mt-4 breadcrumb">
        <ol>
          <li>
            <router-link to="/">
              <span
                ><i class="fa-solid fa-house" style="color: #3881ff"></i></span
              >首頁</router-link
            >
          </li>
          <li></li>
          <li><router-link to="adminPanel">管理頁面</router-link></li>
          <li></li>
          <li>新增</li>
        </ol>
      </div>
      <div class="row mt-4 step">
        <div class="d-flex">
          <div class="boxCircle"><div class="circle -on">1</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle -on">2</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle">3</div></div>
          <div class="boxLine"><div class="line"></div></div>
          <div class="boxCircle"><div class="circle">4</div></div>
        </div>
      </div>
      <div class="row mt-4 stepText">
        <div class="text">填寫文章資訊</div>
        <div class="text -on">上傳照片</div>
        <div class="text">編輯及預覽</div>
        <div class="text">完成!</div>
      </div>
      <div class="row mt-4 addNew">
        <div class="col-12 showInfo mb-3 row">
          <h3 class="col-12 info mb-3">文章資訊</h3>
          <div class="col-12 mb-2 border-bottom">
            <span>編號 : </span><span class="infoText">{{ getItemNo }}</span>
          </div>
          <div class="col-12 mb-2 border-bottom">
            <span>分類 : </span><span class="infoText">{{ getCategory }}</span>
          </div>
          <div class="col-12 mb-2 border-bottom">
            <span>標題 :</span><span class="infoText"> {{ getTopic }}</span>
          </div>
          <div class="col-12 border-bottom">
            <span>日期 : </span><span class="infoText">{{ getBuildDate }}</span>
          </div>
        </div>

        <div class="col-12 add-4 row">
          <label class="col-12"
            >上傳標題照片1
            <input type="file" class="d-none" @change="topicFileChange" />
          </label>
          <span>(放在內容頁面的置頂)</span>
          <div>
            <img
              v-if="topicSelected"
              :src="seeTopic"
              alt="標題照片"
              style="max-width: 35%"
            />
            <p>檔名: {{ topicSelectedName }}</p>
          </div>
        </div>

        <div class="col-12 add-4 row">
          <label class="col-12"
            >上傳標題照片2
            <input type="file" class="d-none" @change="topicFileChange_2" />
          </label>
          <span>(比例約16:9 要放在分類頁面)</span>
          <div>
            <img
              v-if="topicSelected_2"
              :src="seeTopic_2"
              alt="標題照片"
              style="max-width: 35%"
            />
            <p>檔名: {{ topicSelectedName_2 }}</p>
          </div>
        </div>

        <div class="col-12 add-5 mb-3 row">
          <label class="col-12">
            上傳其他照片
            <input
              type="file"
              class="d-none"
              ref="fileInput"
              @change="handleFileChange"
              multiple
          /></label>

          <div class="col-12 row mx-auto mb-3">
            <div
              class="col-3 d-flex flex-column align-items-center justify-content-center"
              style="border: 1px solid #ccc"
              v-for="(file, index) in selectedFiles"
              :key="index"
            >
              <img :src="file.preview" alt="Preview" style="max-width: 40%" />
              <p>檔名{{ index + 1 }} : {{ file.name }}</p>
            </div>
          </div>
        </div>

        <div class="col-12 add-6 mb-3 mt-3 row">
          <div class="add-6-title">
            <h5>影片連結</h5>
            <span>(備註: 主要針對分類是"影片"的部分，若沒有可以不用填寫)</span>
          </div>
          <textarea v-model.trim="add6Content" class="add-6-content"></textarea>
        </div>

        <div class="col-2 add_btn m-auto mb-3">
          <button class="goToStep_2" @click="handClick">送出</button>
        </div>
      </div>
      <div class="row mt-4 top">
        <GotoTop />
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapState, mapGetters } from "vuex";
import { mapActions, mapState } from "vuex";
import GotoTop from "../../components/GotoTop.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      mgrId: "",

      //跳轉icon
      loading: false,

      //處裡標題大圖(內容)
      topicSelected: null, //該圖全部資訊
      seeTopic: null, //路徑
      topicSelectedName: null, //檔名

      //處裡標題大圖(分類)
      topicSelected_2: null, //該圖全部資訊
      seeTopic_2: null, //路徑
      topicSelectedName_2: null, //檔名

      //處理小圖
      selectedFiles: [],
      smallPicFlies: null, //小圖全部資訊

      //確認標題與子照片容量都小於2MB
      topicFileValidity: false,
      topicFileValidity_2: false,
      childFileValidity: false,

      //上傳大小圖片成功後，顯示新增成功
      step1_1: null,
      step1_2: null,
      step1_3: null,

      //影片連結
      add6Content: "",
    };
  },
  components: {
    GotoTop,
  },
  computed: {
    ...mapState(["setAddInfo"]),

    //抓step1的資料
    getItemNo() {
      if (this.$store.state.setAddInfo.length > 0) {
        return this.$store.state.setAddInfo[0].itemNo;
      }
      return null; // 或者返回一個適當的預設值
    },

    //抓step1的資料
    getCategory() {
      //使用ES6結構賦值寫法
      let { category } = this.$store.state.setAddInfo[0] || {};
      return category;
    },

    //抓step1的資料
    getTopic() {
      let { topic } = this.$store.state.setAddInfo[0] || {};
      return topic;
    },

    //抓step1的資料
    getBuildDate() {
      let { buildDate } = this.$store.state.setAddInfo[0] || {};
      return buildDate;
    },
  },
  methods: {
    //每次更新網頁都會檢查管理員權限
    mgrCheck() {
      axios
        .post("php/checkMgrRight.php")
        .then((res) => {
          let info = res.data.mgrId;
          return info;
        })
        .then((res) => {
          if (res) {
            this.mgrId = res;
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          }
        });
    },

    //檢查步驟
    addCheckStep() {
      axios
        .post("php/add/addCheckStep.php")
        .then((res) => {
          if (res.data == "回到步驟1") {
            Swal.fire({
              title: "請從步驟1開始!",
              icon: "warning",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/addStep1");
          } else if (res.data == "回到步驟3") {
            Swal.fire({
              title: "請從步驟3開始!",
              icon: "warning",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/addStep3");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    //處理標題圖片(內容頁面)
    topicFileChange(event) {
      let file = event.target.files[0];
      this.topicSelected = file;
      // console.log(this.topicSelected);

      const reader = new FileReader();
      reader.onload = () => {
        this.seeTopic = reader.result;
      };
      if (file.size > 2 * 1024 * 1024) {
        Swal.fire({
          title: "標題1照片檔案不可超過2MB",
          icon: "warning",
          confirmButtonText: "確認",
          confirmButtonColor: "#72B9EF",
        });
        return;
      }
      reader.readAsDataURL(file);
      this.topicSelectedName = file.name;

      //標示執行過topicFileChange方法
      this.topicFileValidity = true;
    },

    //處理標題圖片(分類葉面)
    topicFileChange_2(event) {
      let file = event.target.files[0];
      this.topicSelected_2 = file;
      // console.log(this.topicSelected_2);

      const reader = new FileReader();
      reader.onload = () => {
        this.seeTopic_2 = reader.result;
      };

      if (file.size > 2 * 1024 * 1024) {
        Swal.fire({
          title: "標題2照片檔案不可超過2MB",
          icon: "warning",
          confirmButtonText: "確認",
          confirmButtonColor: "#72B9EF",
        });
        return;
      }
      reader.readAsDataURL(file);
      this.topicSelectedName_2 = file.name;

      //標示執行過topicFileChange_2方法
      this.topicFileValidity_2 = true;
    },

    //處裡多個圖片
    handleFileChange(event) {
      //使用array.form將FileList轉為陣列，才能使用forEach
      let files = Array.from(event.target.files);
      this.smallPicFlies = files;

      files.forEach((file) => {
        if (file.size > 2 * 1024 * 1024) {
          Swal.fire({
            title: "子照片檔案不可超過2MB",
            icon: "error",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          return;
        }
        let reader = new FileReader(); //先創建一個新的FileReader對象
        reader.onload = () => {
          //當onload事件被觸發時，push selectedFiles的內容進來
          this.selectedFiles.push({
            file, //一整個檔案文件
            name: file.name, //檔名
            preview: reader.result, //一個Data URL，可以用在img的src屬性中，來顯示圖片
          });
        };
        reader.readAsDataURL(file); //用來讀取文件內容，並轉換為Data URL
      });

      //表示執行過handleFileChange方法
      this.childFileValidity = true;
    },

    //點擊按鈕，執行上傳照片影片
    handClick() {
      //跳轉開始
      this.loading = true;

      if (!this.topicFileValidity) {
        Swal.fire({
          title: "標題1照片沒上傳",
          icon: "warning",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else if (!this.topicFileValidity_2) {
        Swal.fire({
          title: "標題2照片沒上傳",
          icon: "warning",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else if (!this.childFileValidity) {
        Swal.fire({
          title: "子照片沒上傳",
          icon: "warning",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      }
      // 串聯 uploadTitleImage 和 uploadSmallImage 與 uploadVideoLink
      // 異步操作Promise.all，用來解決確保，uploadTitleImage()與uploadSmallImage()與uploadVideoLink()都執行完畢後再執行GotoStep3()
      // 這三個函數執行完畢後，需回傳 resolve();告知外部已經完成
      Promise.all([
        this.uploadTitleImage(),
        this.uploadTitleImage_2(),
        this.uploadSmallImage(),
        this.uploadVideoLink(),
      ])
        .then(() => {
          // 在uploadTitleImage 和 uploadSmallImage 和 uploadVideoLink都成功後執行
          this.GotoStep3();
        })
        .catch((error) => {
          console.error("上傳失敗:", error);
          Swal.fire({
            title: "上傳失敗，請通知管理員",
            text:
              error.message || "可能原因: 檔案超過2M、未選檔案、檔案格式不完整",
            icon: "warning",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
        })
        .finally(() => {
          //跳轉隱藏
          this.loading = false;
        });
    },

    //上傳標題1照片
    uploadTitleImage() {
      return new Promise((resolve, reject) => {
        let form = new FormData();
        form.append("itemNo", this.getItemNo);
        form.append("topic", this.getTopic);
        form.append("category", this.getCategory);
        form.append("topicImage", this.topicSelected);
        axios
          .post("php/add/addBigPic.php", form)
          .then((res) => {
            // this.step1_2 = res.data;
            // this.gotoStep2();
            if (res.data == "圖片上傳成功") {
              axios.post("php/add/addBigPicInfo.php", form).then((res) => {
                console.log(res.data);
                this.step1_1 = "成功";
                resolve(); // 告訴外部這個函數已經成功完成
              });
            } else {
              reject(new Error("圖片上傳失敗，請聯絡管理員"));
            }
          })
          .catch((error) => {
            // console.log(error.res);
            reject(error); // 告訴外部這個函數失敗了
          });
      });
    },

    //上傳標題2照片
    uploadTitleImage_2() {
      return new Promise((resolve, reject) => {
        let form = new FormData();
        form.append("itemNo", this.getItemNo);
        form.append("topic", this.getTopic);
        form.append("category", this.getCategory);
        form.append("topicImage_2", this.topicSelected_2);
        axios
          .post("php/add/addBigPic2.php", form)
          .then((res) => {
            if (res.data == "圖片上傳成功") {
              axios.post("php/add/addBigPicInfo2.php", form).then((res) => {
                console.log(res.data);
                this.step1_2 = "成功";
                resolve(); // 告訴外部這個函數已經成功完成
              });
            } else {
              reject(new Error("圖片上傳失敗，請聯絡管理員"));
            }
          })
          .catch((error) => {
            // console.log(error.res);
            reject(error); // 告訴外部這個函數失敗了
          });
      });
    },

    //上傳子照片
    uploadSmallImage() {
      return new Promise((resolve, reject) => {
        let form = new FormData();
        form.append("itemNo", this.getItemNo);
        form.append("topic", this.getTopic);
        form.append("category", this.getCategory);

        for (let i = 0; i < this.smallPicFlies.length; i++) {
          form.append("image[]", this.smallPicFlies[i]);
        }

        axios
          .post("php/add/addSmallPic.php", form)
          .then((res) => {
            let info = res.data;
            if (info.includes("圖片上傳成功") == true) {
              axios.post("php/add/addSmallPicInfo.php", form).then((res) => {
                console.log(res.data);
                this.step1_3 = "成功";
                resolve(); // 告訴外部這個函數已經成功完成
              });
            } else {
              reject(new Error("圖片上傳失敗，請聯絡管理員"));
            }
          })
          .catch((error) => {
            console.log(error.response);
            reject(error); // 告訴外部這個函數失敗了
          });
      });
    },

    //上傳videoLink
    uploadVideoLink() {
      return new Promise((resolve, reject) => {
        let form = new FormData();
        form.append("itemNo", this.getItemNo);
        form.append("videoLink", this.add6Content);
        axios
          .post("php/add/addVideoLink.php", form)
          .then((res) => {
            if (res.data == "影片連結上傳成功") {
              // console.log(res.data);
              resolve(); // 告訴外部這個函數已經成功完成
            }
          })
          .catch((error) => {
            reject(error); // 告訴外部這個函數失敗了
          });
      });
    },

    //到步驟3
    GotoStep3() {
      //標題圖片新增成功 / 新增成功
      if (
        this.step1_1 == "成功" &&
        this.step1_2 == "成功" &&
        this.step1_3 == "成功"
      ) {
        Swal.fire({
          title: "成功!前往下一步",
          icon: "success",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.$router.push("/addStep3");
      }
    },

    //登出
    logout() {
      axios.post("php/logOut.php").then((res) => {
        // console.log(res.data);
        if (res.data == "已登出後台!") {
          this.mgrId = "";
          Swal.fire({
            title: res.data,
            icon: "success",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          this.$router.push("/logIn");
        }
      });
    },

    //去store取資料
    ...mapActions(["fetchAddInfo"]),
  },
  mounted() {
    this.mgrCheck();
    this.addCheckStep(); //檢查步驟
    this.fetchAddInfo();
  },
};
</script>
