<template>
  <div id="mingContent">
    <div class="row mt-4 breadcrumb">
      <ol>
        <li>
          <router-link to="/">
            <span><i class="fa-solid fa-house" style="color: #3881ff"></i></span
            >首頁</router-link
          >
        </li>
        <li></li>
        <li>{{ getCategory }}</li>
      </ol>
    </div>

    <!-- 文章內容 -->
    <div class="container">
      <div class="row preview">
        <div class="col p-4 mb-5">
          <!-- 如果分類不是影片，就顯示圖片，反之則顯示影片 -->
          <div class="topicImage mb-4" v-if="getCategory !== '影片'">
            <img :src="imageUrl()" :alt="getTitle" :title="getTitle" />
          </div>

          <div class="videoLink mb-4" v-else>
            <div class="getVideo" v-html="getVideo"></div>
          </div>

          <h1>{{ getTopic }}</h1>
          <ul>
            <li>
              <span><i class="fa-regular fa-clock"></i></span>{{ getDate }}
            </li>
          </ul>
          <div class="mingContent" v-html="getContent"></div>

          <div class="interest">
            <div class="title"><p>你可能會感興趣 :</p></div>
            <div
              class="interestLink"
              v-for="(info, index) in interestTopic"
              :key="index"
            >
              <a :href="interestLink(info.itemNo)" @click="seeMore(info.itemNo)"
                ><div>{{ info.topic }}</div></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 近期瀏覽 -->
    <div class="like">
      <div class="container p-1">
        <div class="row">
          <h3>近期瀏覽</h3>
          <div class="col-lg-7 col-12 carouselCon">
            <b-carousel
              id="carousel-1"
              v-model="slide"
              :interval="3000"
              controls
              img-width="1024"
              img-height="480"
              style="text-shadow: 1px 1px 2px #333"
              @sliding-start="onSlideStart"
              @sliding-end="onSlideEnd"
            >
              <b-carousel-slide
                v-for="(infoArray, outerIndex) in parsedInfos"
                :key="outerIndex"
              >
                <template v-slot:img>
                  <img
                    v-for="(info, innerIndex) in infoArray"
                    :key="innerIndex"
                    class="d-block img-fluid w-100"
                    width="1024"
                    height="480"
                    :src="info.topicImage"
                    :alt="info.topic"
                    @click="seeMore(info.itemNo)"
                  />
                </template>
                <div
                  v-for="(info, innerIndex) in infoArray"
                  :key="innerIndex"
                  class="text"
                  @click="seeMore(info.itemNo)"
                >
                  <div
                    class="textContent"
                    v-html="md.render(info.content)"
                  ></div>
                </div>
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </div>
    </div>

    <!-- 顯示社群媒體 -->
    <showMedia />

    <GotoTop />
  </div>
</template>

<script>
import { mapState } from "vuex";
import md from "@/markdown";
import axios from "axios";
import GotoTop from "../../components/GotoTop.vue";
import showMedia from "../../components/showMedia.vue";

export default {
  props: ["sn"],
  components: {
    showMedia,
    GotoTop,
  },
  data() {
    return {
      storage: sessionStorage,

      //近期瀏覽資料
      infos: [],
      parsedInfos: [],

      md: md,
      slide: 0,
      sliding: null,

      //ogMeta
      ogTitle: "",
      ogCategory: "",
      ogImgUrl: "",
      ogVideoLink: "",
      ogBuildDate: "",
      keywords: "",
      ogContent: "",
      keywordsArray: [],

      //感興趣的文章(有三個)
      interestTopic: [],
    };
  },
  metaInfo() {
    let arrayTags = [];

    this.keywordsArray.forEach((keyword) => {
      arrayTags.push({
        property: "article:tag",
        content: keyword,
      });
    });

    return {
      title: `${this.ogTitle} | ${this.ogCategory} | 部落格 | 張宏銘中醫師鯨生情緣`,
      meta: [
        {
          name: "title",
          content: this.ogTitle,
        },
        {
          name: "keywords",
          content: this.keywords,
        },
        {
          name: "news_keywords",
          content: this.keywords,
        },
        {
          name: "description",
          content: this.limitedContent,
        },
        {
          name: "author",
          content: "部落格 | 張宏銘中醫師鯨生情緣",
        },
        {
          name: "copyright",
          content: "部落格 | 張宏銘中醫師鯨生情緣",
        },
        {
          property: "og:title",
          content: this.ogTitle,
        },
        {
          property: "og:url",
          content: this.getOGUrl(),
        },
        {
          property: "og:image",
          content: this.imageUrl(),
        },
        {
          property: "og:keywords",
          content: `${this.ogTitle}, ${this.ogCategory}, 部落格, 張宏銘中醫師鯨生情緣, 中醫師, 桃園中醫`,
        },
        {
          property: "og:description",
          content: this.limitedContent,
        },
        {
          property: "og:video",
          content: this.videoUrl(),
        },
        {
          property: "og:site_name",
          content: this.ogTitle,
        },
        {
          property: "og:type",
          content: "article",
        },
        {
          property: "article:publisher",
          content: "https://www.facebook.com/SuperWhaleMing/",
        },
        {
          property: "article:author",
          content: "https://www.facebook.com/SuperWhaleMing/",
        },
        {
          property: "article:published_time",
          content: this.ogBuildDate,
        },
        ...arrayTags,
      ],
    };
  },
  computed: {
    ...mapState(["content"]),

    //取目前網站根目錄
    baseUrl() {
      return window.location.origin;
    },

    //for meta : content內容
    getDescription() {
      return this.content && this.content.length > 0
        ? this.content[0].content
        : "";
    },

    //for meta: 內容只取n行
    limitedContent() {
      //先渲染md，渲染後會出現<p><u>等標籤
      let mdContent = md.render(this.ogContent);

      //建立一個暫時的div，把mdContent傳成純文字去除<p><u>標籤
      let tempDiv = document.createElement("div");
      tempDiv.innerHTML = mdContent;
      let text = tempDiv.textContent || tempDiv.innerText;

      let maxLines = 8; // 最大行數
      let setText = text.split("\n");
      // console.log(setText);

      let limitedLines = setText.slice(0, maxLines).join(" ");
      return limitedLines;
    },

    //for meta: 內容只取https / 另一個方法是this.videoUrl()也可以
    limitedVideo() {
      // (?=\?) 結尾是?但不包括它
      let regex = /https:\/\/(.*?)(?=\?)/g;
      let match = this.ogVideoLink.match(regex);
      let result = match ? match[0] : "";

      return result;
    },

    getTitle() {
      return this.content && this.content.length > 0
        ? this.content[0].topic
        : "";
    },

    getDate() {
      return this.content && this.content.length > 0
        ? this.content[0].buildDate.slice(0, 10)
        : "";
    },

    getVideo() {
      if (this.content && this.content.length > 0) {
        return this.content[0].videoLink;
      }
      return "";
    },

    getCategory() {
      if (this.content && this.content.length > 0) {
        return this.content[0].category;
      }
      return "";
    },

    getTopic() {
      if (this.content && this.content.length > 0) {
        return this.content[0].topic;
      }
      return "";
    },

    getContent() {
      if (this.content && this.content.length > 0) {
        let baseUrl = location.origin;
        let mdContent = md.render(this.content[0].content);

        //將圖片改為絕對路徑
        mdContent = mdContent.replace(
          /<img src="(.+?)"/g,
          `<img src="${baseUrl}/$1"`
        );
        return mdContent;
      }
      return "";
    },
  },
  methods: {
    //感興趣文章連結
    interestLink(itemNo) {
      return `${this.baseUrl}/content?sn=${itemNo}`;
    },

    //感興趣文章(去後台取3個)
    async interest(category) {
      let form = new FormData();
      form.append("category", category);
      form.append("itemNo", this.sn);
      try {
        let res = await axios.post("php/content/interest.php", form);
        this.interestTopic = res.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    //更新meta資訊
    async updateMeta() {
      try {
        let res = await axios.get(`php/content.php?sn=${this.sn}`);
        let title = res.data[0].topic;
        let category = res.data[0].category;
        let imgUrl = res.data[0].topicImage;
        let videoLink = res.data[0].videoLink;
        let buildDate = res.data[0].buildDate;

        this.ogTitle = title;
        this.ogCategory = category;
        this.ogImgUrl = imgUrl;
        this.ogVideoLink = videoLink;
        this.ogBuildDate = buildDate;

        //感興趣文章(去後台取3個)
        this.interest(category);
      } catch (error) {
        console.log(error.response);
      }
    },

    //新增meta tag (取keyword & content)
    async getKeywords_content() {
      try {
        let res = await axios.get(`php/content/metaContent.php?sn=${this.sn}`);
        let keywords = res.data.keywords;
        let content = res.data.content;

        this.keywords = keywords;
        this.ogContent = content;
        //把keywords切割成陣列
        this.keywordsArray = this.keywords.split(", ");
      } catch (error) {
        console.log(error.response);
      }
    },

    //for OG meta tag
    getOGUrl() {
      return window.location.href;
    },

    //for OG meta tag (絕對路徑圖片url)
    imageUrl() {
      //根目錄與相對路徑結合，變成完整https://開頭的url
      let absoImageUrl = this.baseUrl + "/" + this.ogImgUrl;

      return absoImageUrl;
    },

    //for OG meta tag(影片路徑)取https到?之前/ 另一個方法是limitedVideo使用正規表示法也可以
    videoUrl() {
      if (this.ogCategory == "影片") {
        if (this.ogVideoLink) {
          let parts = this.ogVideoLink.split('src="');

          if (parts.length > 1) {
            let urlParts = parts[1].split('"');

            if (urlParts.length > 0) {
              let videoId = urlParts[0].split("/").pop().split("?")[0];
              return `https://www.youtube.com/watch?v=${videoId}`;
            }
          }
        }
      } else {
        return null;
      }
    },

    fetchData() {
      this.$store.dispatch("fetchContent", this.sn);
    },

    //使用異部操作，就不需要.then()
    async showLike() {
      //取得session sotrage的資料
      let sessionData = sessionStorage.getItem("addItemNo");

      //檢查是否有資料
      if (sessionData) {
        let form = new FormData();
        form.append("itemNo", sessionData);
        try {
          let res = await axios.post("php/content/getStorage.php", form);

          this.infos = res.data;

          //轉成普通陣列
          this.parsedInfos = JSON.parse(JSON.stringify(this.infos));
        } catch (error) {
          console.error("錯誤", error);
        }
      }
    },

    //看內容
    seeMore(info) {
      let itemNo = info;

      this.saveRecord(itemNo); //儲存到sessionstorage做瀏覽紀錄

      this.$router.push({ name: "mingContent", query: { sn: itemNo } });
    },

    //近期瀏覽
    saveRecord(info) {
      //建立key
      if (this.storage["addItemNo"] == null) {
        this.storage["addItemNo"] = "";
      }

      //儲存value
      if (!this.storage[info]) {
        //儲存資料
        this.storage["addItemNo"] += info + ", ";
        // 表示已經儲存過
        this.storage[info] = true;
      }
    },

    //左右輪播按鈕
    onSlideStart() {
      this.sliding = true;
    },

    //左右輪播按鈕
    onSlideEnd() {
      this.sliding = false;
    },
  },
  created() {
    this.$nextTick(async () => {
      await this.updateMeta(); //取大部分的meta值
      await this.getKeywords_content(); //取meta的keyword / content放進來
      await this.fetchData();
      await this.showLike();
    });
  },
};
</script>
