<template>
  <div class="banner">
    <img
      class="ba_img"
      :src="absoImageUrl"
      alt="部落格 | 張宏銘中醫師 - 鯨生情緣"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      absoImageUrl: "",
    };
  },
  methods: {
    imageUrl() {
      // 目前網站根目錄的url
      let baseUrl = location.origin;
      // 根目錄與相對路徑結合，變成完整https://開頭的url
      this.absoImageUrl = baseUrl + "/" + "banner/banner_1.jpg";
    },
  },
  mounted() {
    this.imageUrl();
  },
};
</script>
