<template>
  <div id="mindAdd">
    <!-- 跳轉畫面 -->
    <div class="loading" v-if="loading"></div>
    <div class="back-title">
      <div class="back-title_box">
        <div>後台管理系統</div>
        <div class="logout" @click="logout">登出</div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-4">
        <div class="add_manager">
          管理員: <span>{{ mgrId }}</span>
        </div>
      </div>
      <div class="row mt-4 breadcrumb">
        <ol>
          <li>
            <router-link to="/">
              <span
                ><i class="fa-solid fa-house" style="color: #3881ff"></i></span
              >首頁</router-link
            >
          </li>
          <li></li>
          <li><router-link to="adminPanel">管理頁面</router-link></li>
          <li></li>
          <li>新增</li>
        </ol>
      </div>
      <div class="row mt-4 step">
        <div class="d-flex">
          <div class="boxCircle"><div class="circle -on">1</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle -on">2</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle -on">3</div></div>
          <div class="boxLine"><div class="line -on"></div></div>
          <div class="boxCircle"><div class="circle">4</div></div>
        </div>
      </div>
      <div class="row mt-4 stepText">
        <div class="text">填寫文章資訊</div>
        <div class="text">上傳照片</div>
        <div class="text -on">編輯及預覽</div>
        <div class="text">完成!</div>
      </div>
      <div class="row mt-4 addNew">
        <div class="col-12 showInfo mb-3 row">
          <h3 class="col-12 info mb-3">文章資訊</h3>
          <div class="col-12 mb-2 border-bottom">
            <span>編號 : </span><span class="infoText">{{ getItemNo }}</span>
          </div>
          <div class="col-12 mb-2 border-bottom">
            <span>分類 : </span><span class="infoText">{{ getCategory }}</span>
          </div>
          <div class="col-12 mb-2 border-bottom">
            <span>標題 :</span><span class="infoText"> {{ getTopic }}</span>
          </div>
          <div class="col-12 border-bottom">
            <span>日期 : </span><span class="infoText">{{ getBuildDate }}</span>
          </div>
        </div>

        <div class="col-12 add-4 row">
          <div class="col-12 titlePic">標題1照片</div>
          <div class="titleImg">
            <img :src="getTopicImg" />
            <p>路徑: {{ getTopicImg }}</p>
          </div>
        </div>

        <div class="col-12 add-4 row">
          <div class="col-12 titlePic">標題2照片</div>
          <div class="titleImg">
            <img :src="getTopicImg_2" />
            <p>路徑: {{ getTopicImg_2 }}</p>
          </div>
        </div>

        <div class="col-12 add-5 mb-3 row">
          <div class="col-12 childPic">其他照片</div>
          <div class="col-12 row mx-auto mb-3">
            <div
              v-for="(info, index) in ChildPic"
              :key="index"
              class="col-3 childBox"
            >
              <img :src="info.image" />
              <div class="childPath">
                <p>{{ info.image }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 add-6 mb-3 row">
          <div class="add-6-title">
            <h5>影片連結</h5>
          </div>
          <div class="col-12 row mb-3 add-6-video">
            <div v-html="getVideoLink" class="add-6-showVideo"></div>
          </div>
        </div>
      </div>

      <div class="row mt-4 goToStep4Btn">
        <button class="goToStep_4" @click="submitAll">送出</button>
      </div>

      <div class="row mt-4 edit">
        <div class="col p-0">
          <h2>編輯</h2>
          <textarea v-model="mdContent" ref="getContText"></textarea>
        </div>
      </div>
      <div class="row mt-4 preview">
        <div class="col p-0">
          <h2>預覽</h2>
          <div class="content p-4" v-html="htmlContent"></div>
        </div>
      </div>
      <div class="row mt-4 top">
        <GotoTop />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import md from "@/markdown";
import GotoTop from "../../components/GotoTop.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      mgrId: "",
      mdContent: "",
      htmlContent: "",

      //跳轉icon
      loading: false,
    };
  },
  components: {
    GotoTop,
  },
  computed: {
    ...mapState(["setAddInfo"]),
    ...mapState(["setChildPic"]),

    //抓step1的資料
    getItemNo() {
      if (this.$store.state.setAddInfo.length > 0) {
        return this.$store.state.setAddInfo[0].itemNo;
      }
      return null; // 或者返回一個適當的預設值
    },

    //抓step1的資料
    getCategory() {
      //使用ES6結構賦值寫法
      let { category } = this.$store.state.setAddInfo[0] || {};
      return category;
    },

    //抓step1的資料
    getTopic() {
      let { topic } = this.$store.state.setAddInfo[0] || {};
      return topic;
    },

    //抓step1的資料
    getBuildDate() {
      let { buildDate } = this.$store.state.setAddInfo[0] || {};
      return buildDate;
    },

    //抓step2的資料
    getTopicImg() {
      let { topicImage } = this.$store.state.setAddInfo[0] || {};
      return topicImage;
    },

    //抓step2的資料
    getTopicImg_2() {
      let { topicImage_2 } = this.$store.state.setAddInfo[0] || {};
      return topicImage_2;
    },

    //抓step2的資料
    ChildPic() {
      return this.setChildPic;
    },

    //抓step2的資料
    getVideoLink() {
      let { videoLink } = this.$store.state.setAddInfo[0] || {};
      return videoLink;
    },
  },
  methods: {
    ...mapActions(["fetchTexts"]),

    //每次更新網頁都會檢查管理員權限
    mgrCheck() {
      axios
        .post("php/checkMgrRight.php")
        .then((res) => {
          let info = res.data.mgrId;
          return info;
        })
        .then((res) => {
          if (res) {
            this.mgrId = res;
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/logIn");
          }
        });
    },

    //檢查步驟
    addCheckStep() {
      axios
        .post("php/add/addCheckStep.php")
        .then((res) => {
          if (res.data == "回到步驟1") {
            Swal.fire({
              title: "請從步驟1開始!",
              icon: "warning",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/addStep1");
          } else if (res.data == "回到步驟2") {
            Swal.fire({
              title: "請從步驟2開始!",
              icon: "warning",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/addStep2");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    //存輸入的資料
    submitAll() {
      //跳轉開始
      this.loading = true;

      let form = new FormData();
      form.append("content", this.mdContent);

      if (this.mdContent == "") {
        Swal.fire({
          title: "內容不可空白!",
          icon: "warning",
          confirmButtonText: "確定",
          confirmButtonColor: "#72B9EF",
        });
        this.loading = false;
        return;
      } else {
        axios
          .post("php/add/addContent.php", form)
          .then((res) => {
            let info = res.data;
            // console.log(info);
            if (info == "文章新增成功") {
              Swal.fire({
                title: "全部上傳完畢",
                icon: "success",
                confirmButtonText: "確定",
                confirmButtonColor: "#72B9EF",
              });
              this.$router.push("/addStep4");
            }
          })
          .catch((error) => {
            console.log(error.response);
          })
          .finally(() => {
            //跳轉隱藏
            this.loading = false;
          });
      }
    },

    //登出
    logout() {
      axios.post("php/logOut.php").then((res) => {
        // console.log(res.data);
        if (res.data == "已登出後台!") {
          this.mgrId = "";
          Swal.fire({
            title: res.data,
            icon: "success",
            confirmButtonText: "確定",
            confirmButtonColor: "#72B9EF",
          });
          this.$router.push("/logIn");
        }
      });
    },

    //去store取資料
    ...mapActions(["fetchAddInfo"]),
    ...mapActions(["fetchChildPic"]),
  },
  watch: {
    mdContent(newVal, oldVal) {
      this.htmlContent = md.render(newVal);
      console.log(oldVal);
    },
  },
  mounted() {
    this.mgrCheck();
    this.addCheckStep(); //檢查步驟
    this.fetchAddInfo(); //mingAddStep_2要抓的資料
    this.fetchChildPic(); //mingAddStep_3要抓的資料(圖片)
  },
};
</script>
