import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const CLEAR_SEARCH = "CLEAR_SEARCH";

// 在全局範圍定義 formatDate 函數: 要產生xml檔for SEO
function formatDate(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const store = new Vuex.Store({
  state: {
    //1.建立空陣列
    content: "",
    tab_1: [],
    tab_2: [],
    tab_3: [],
    tab_4: [],
    tab_5: [],
    tab_6: [],
    tab_6_1: [],
    tab_6_2: [],
    tab_7: [],
    tab_8: [],
    tab_10: [],
    setAddInfo: [], //mingAddStep_2要抓的資料
    setChildPic: [], //mingAddStep_3要抓的資料

    //前台的資料
    homePage_1: [],
    home_2: [],
    home_3: [],
    life_all: [],
    life_1: [],
    life_2: [],
    life_3: [],
    med: [],
    acu: [],
    video: [],
    search: [],
  },
  getters: {
    tab1Length: (state) => state.tab_1.length,
    tab2Length: (state) => state.tab_2.length,
    tab3Length: (state) => state.tab_3.length,
    tab4Length: (state) => state.tab_4.length,
    tab5Length: (state) => state.tab_5.length,
    tab6Length: (state) => state.tab_6.length,
    tab7Length: (state) => state.tab_7.length,
    tab10Length: (state) => state.tab_10.length,

    //前台的資料
    medLength: (state) => state.med.length,
    acuLength: (state) => state.acu.length,
    lifeAllLength: (state) => state.life_all.length,
    life_1_Length: (state) => state.life_1.length,
    life_2_Length: (state) => state.life_2.length,
    life_3_Length: (state) => state.life_3.length,
    videoLength: (state) => state.video.length,
    searchLength: (state) => state.search.length,
  },
  mutations: {
    //清空state的search陣列
    [CLEAR_SEARCH](state) {
      state.search = [];
    },

    getContent(state, content) {
      this.content = [];
      state.content = content;
    },

    getSearch(state, search) {
      state.search = search;
    },

    setTab_1(state, tab_1) {
      state.tab_1 = tab_1;
    },

    setTab_2(state, tab_2) {
      state.tab_2 = tab_2;
    },

    setTab_3(state, tab_3) {
      state.tab_3 = tab_3;
    },

    setTab_4(state, tab_4) {
      state.tab_4 = tab_4;
    },

    setTab_5(state, tab_5) {
      state.tab_5 = tab_5;
    },

    setTab_6(state, tab_6) {
      state.tab_6 = tab_6;
    },

    setTab_6_1(state, tab_6_1) {
      state.tab_6_1 = tab_6_1;
    },

    setTab_6_2(state, tab_6_2) {
      state.tab_6_2 = tab_6_2;
    },

    setTab_7(state, tab_7) {
      state.tab_7 = tab_7;
    },

    //訪客人數
    setTab_8(state, tab_8) {
      state.tab_8 = tab_8;
    },

    //meta關鍵字
    setTab_10(state, tab_10) {
      state.tab_10 = tab_10;
    },

    setAddInfo(state, setAddInfo) {
      state.setAddInfo = setAddInfo;
    },

    setChildPic(state, setChildPic) {
      state.setChildPic = setChildPic;
    },

    setHome_1(state, homePage_1) {
      state.homePage_1 = homePage_1;
    },

    setHome_2(state, home_2) {
      state.home_2 = home_2;
    },

    setHome_3(state, home_3) {
      state.home_3 = home_3;
    },

    setMed(state, med) {
      state.med = med;
    },

    setAcu(state, acu) {
      state.acu = acu;
    },

    setLife(state, life_all) {
      state.life_all = life_all;
    },

    setLife_1(state, life_1) {
      state.life_1 = life_1;
    },

    setLife_2(state, life_2) {
      state.life_2 = life_2;
    },

    setLife_3(state, life_3) {
      state.life_3 = life_3;
    },

    setVideo(state, video) {
      state.video = video;
    },
  },
  actions: {
    //更新訪客人數(+1)
    updateVisitor() {
      fetch("php/admin/visitor.php").then((res) => res.json());
    },

    //抓文章內容
    fetchContent({ commit }, sn) {
      fetch(`php/content.php?sn=${sn}`)
        .then((res) => res.json())
        .then((res) => {
          commit("getContent", res);
        });
    },

    //抓search資料
    fetchSearch({ commit }, keyword) {
      //先清空state的search陣列
      commit(CLEAR_SEARCH);

      fetch(`php/content/search.php?keyword=${keyword}`)
        .then((res) => res.json())
        .then((res) => {
          commit("getSearch", res);
        });
    },

    //抓後台tab_1資料
    backendTab_1({ commit }) {
      fetch("php/admin/tab_1.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_1", res);
        });
    },

    //抓後台tab_2資料
    backendTab_2({ commit }) {
      fetch("php/admin/tab_2.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_2", res);
        });
    },

    //抓後台tab_3資料
    backendTab_3({ commit }) {
      fetch("php/admin/tab_3.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_3", res);
        });
    },

    //抓後台tab_4資料
    backendTab_4({ commit }) {
      fetch("php/admin/tab_4.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_4", res);
        });
    },

    //抓後台tab_5資料
    backendTab_5({ commit }) {
      fetch("php/admin/tab_5.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_5", res);
        });
    },

    //抓後台tab_6資料
    backendTab_6({ commit }) {
      fetch("php/admin/tab_6.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_6", res);
        });
    },

    //抓後台tab_6_1資料
    backendTab_6_1({ commit }) {
      fetch("php/admin/tab_6_1.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_6_1", res);
        });
    },

    //抓後台tab_6_2資料
    backendTab_6_2({ commit }) {
      fetch("php/admin/tab_6_2.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_6_2", res);
        });
    },

    //抓後台tab_7資料
    backendTab_7({ commit }) {
      fetch("php/admin/tab_7.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_7", res);
        });
    },

    //抓後台tab_8資料 (訪客人數)
    async backendTab_8({ commit }) {
      await fetch("php/admin/tab_8.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_8", res);
        });
    },

    //抓後台tab_10資料 (meta關鍵字)
    backendTab_10({ commit }) {
      fetch("php/admin/tab10.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setTab_10", res);
        });
    },

    //mingAddStep_2要抓的資料
    fetchAddInfo({ commit }) {
      fetch("php/add/addStep2.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setAddInfo", res);
        });
    },

    //mingAddStep_3要抓的資料
    fetchChildPic({ commit }) {
      fetch("php/add/addStep3.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setChildPic", res);
        });
    },

    //抓首頁資料-精選文章
    fetchHome_1({ commit }) {
      fetch("php/content/homePage.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setHome_1", res);
        });
    },

    //抓首頁資料-關鍵字
    fetchHome_2({ commit }) {
      fetch("php/content/homeKey.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setHome_2", res);
        });
    },

    //抓首頁資料-吃喝玩樂
    fetchHome_3({ commit }) {
      fetch("php/content/homePageLife.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setHome_3", res);
        });
    },

    //抓醫藥文章
    fetchMed({ commit }) {
      fetch("php/content/med.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setMed", res);
        });
    },

    //抓穴道文章
    fetchAcu({ commit }) {
      fetch("php/content/acu.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setAcu", res);
        });
    },

    //抓所有生活文章
    fetchLife({ commit }) {
      fetch("php/content/life.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setLife", res);
        });
    },

    //抓所有life_1
    fetchLife_1({ commit }) {
      fetch("/php/content/life_1.php") // 使用根目錄的絕對路徑
        .then((res) => res.json())
        .then((res) => {
          commit("setLife_1", res);
        });
    },

    //抓所有life_2
    fetchLife_2({ commit }) {
      fetch("/php/content/life_2.php") // 使用根目錄的絕對路徑
        .then((res) => res.json())
        .then((res) => {
          commit("setLife_2", res);
        });
    },

    //抓所有life_3
    fetchLife_3({ commit }) {
      fetch("/php/content/life_3.php") // 使用根目錄的絕對路徑
        .then((res) => res.json())
        .then((res) => {
          commit("setLife_3", res);
        });
    },

    //抓影片
    fetchVideo({ commit }) {
      fetch("php/content/video.php")
        .then((res) => res.json())
        .then((res) => {
          commit("setVideo", res);
        });
    },

    getXML() {
      let base_url = "https://whalemingdoctor.tw/";

      //建立<urlset>元素
      let urlset = document.createElement("urlset");
      urlset.setAttribute(
        "xmlns",
        "http://www.sitemaps.org/schemas/sitemap/0.9"
      );

      // 建立根目錄
      let rootUrl = document.createElement("url");
      let locRoot = document.createElement("loc");
      locRoot.textContent = base_url;
      rootUrl.appendChild(locRoot);

      let lastmodRoot = document.createElement("lastmod");
      lastmodRoot.textContent = formatDate(new Date());
      rootUrl.appendChild(lastmodRoot);

      let changefreqRoot = document.createElement("changefreq");
      changefreqRoot.textContent = "monthly";
      rootUrl.appendChild(changefreqRoot);

      let priorityRoot = document.createElement("priority");
      priorityRoot.textContent = "1.0";
      rootUrl.appendChild(priorityRoot);
      urlset.appendChild(rootUrl);

      // 生成URL
      for (let sn = 1; sn <= 62; sn++) {
        let url = base_url + `content?sn=${sn}`;
        let urlElement = document.createElement("url");

        let loc = document.createElement("loc");
        loc.textContent = url;
        urlElement.appendChild(loc);

        let lastmod = document.createElement("lastmod");
        lastmod.textContent = formatDate(new Date());
        urlElement.appendChild(lastmod);

        urlset.appendChild(urlElement);
      }

      // 將 XML 字串寫入檔案
      let xmlString = new XMLSerializer().serializeToString(urlset);
      console.log(xmlString);

      //生成並下載xml檔案
      let blob = new Blob([xmlString], { type: "application/xml" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "sitemap.xml";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});

export default store;
