<template>
  <div class="logIn">
    <div class="logInBox">
      <div class="logInName"><h3>歡迎光臨後台管理頁面</h3></div>
      <div class="lonInForm">
        <h4>登入 LogIn</h4>
        <form @submit.prevent="sendForm">
          <input type="text" placeholder="帳號" v-model="id" />
          <input type="password" placeholder="密碼" v-model="password" />
          <div class="btn"><button type="submit">登入</button></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      id: "",
      password: "",
    };
  },
  methods: {
    sendForm() {
      let params = new URLSearchParams();
      params.append("id", this.id);
      params.append("password", this.password);

      axios
        .post("php/logIn.php", params)
        .then((res) => {
          // console.log(res.data.mgrId);
          let info = res.data.mgrId;
          return info;
        })
        .then((res) => {
          if (res) {
            Swal.fire({
              title: "驗證成功!",
              text: "成功進入後台",
              icon: "success",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
            this.$router.push("/adminPanel");
          } else {
            Swal.fire({
              title: "帳密錯誤!",
              text: "請重新輸入",
              icon: "error",
              confirmButtonText: "確定",
              confirmButtonColor: "#72B9EF",
            });
          }
        })
        .catch((error) => console.log(`錯誤: ${error}`));
    },
  },
};
</script>
