<template>
  <div class="tab">
    <!-- 跳轉畫面 -->
    <div class="loading" v-if="loading"></div>

    <h3>生活文章管理</h3>
    <div class="list">
      <div class="num">
        共 <span>{{ length }}</span> 筆
      </div>
    </div>

    <div class="tab2_content">
      <b-table striped hover :items="items" :fields="fields" class="table2">
        <!-- 標題 -->
        <template v-slot:cell(topic)="data">
          <div class="mdTopic">{{ data.value }}</div>
        </template>

        <!-- 內容 -->
        <template v-slot:cell(mdContent)="data">
          <div class="mdContent" v-html="data.value"></div>
        </template>

        <!-- 編輯 -->
        <template v-slot:cell(edit)="data">
          <button
            class="editBtn"
            v-b-modal.modal-xl-tab2
            @click="content(data.item)"
          >
            {{ data.value }}
          </button>
        </template>
      </b-table>

      <!-- 燈箱 -->
      <b-modal
        id="modal-xl-tab2"
        modal-class="tab_2"
        size="xl"
        ref="modal"
        title="編輯"
        cancel-title="取消"
        ok-title="確認"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <form class="tab2Form" ref="form" @submit.prevent="handleOk">
          <div class="formBodyInfo">
            <div class="formBody">
              <span>編號 : </span>
              <span class="formText">{{ editItemNo }}</span>
            </div>

            <div class="formBody">
              <span>分類 : </span>
              <span class="formText">{{ editCategory }}</span>
            </div>

            <div class="formBody">
              <span class="formTopic">標題 : </span>
              <b-form-input
                type="text"
                v-model.trim="editTopic"
                :state="isTopicValid && isTopicNotEmpty ? null : false"
              ></b-form-input>
              <b-form-invalid-feedback v-if="!isTopicValid && !isOriginalTopic"
                >標題重複!</b-form-invalid-feedback
              >
              <b-form-invalid-feedback v-if="!isTopicNotEmpty"
                >不可空白</b-form-invalid-feedback
              >
            </div>

            <div class="formBody">
              <span class="formStatus">狀態 : </span>
              <select v-model="editStatus">
                <option value="正常">正常</option>
                <option value="停權">停權</option>
              </select>
            </div>
          </div>

          <div class="formBodyContent">
            <h4>編輯內容</h4>
            <b-form-textarea
              id="content-input"
              v-model.trim="editContent"
              :state="contentValidation"
              rows="5"
              max-rows="15"
              required
            ></b-form-textarea>
            <b-form-invalid-feedback :state="contentValidation"
              >不可空白</b-form-invalid-feedback
            >

            <h4>預覽</h4>
            <div v-html="editContentHTML"></div>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import md from "@/markdown";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      fields: [
        {
          key: "itemNo",
          label: "編號",
          sortable: true,
        },
        {
          key: "category",
          label: "分類",
          sortable: true,
        },
        {
          key: "topic",
          label: "標題",
        },
        {
          key: "mdContent",
          label: "內容",
        },
        {
          key: "buildDate",
          label: "建立日期",
          sortable: true,
        },
        {
          key: "status",
          label: "目前狀態",
          sortable: true,
        },
        {
          key: "edit",
          label: "編輯",
        },
      ],

      //跳轉icon
      loading: false,

      //要修改的資訊
      editItemNo: "",
      editCategory: "",
      editTopic: "",
      editStatus: "",
      editContent: "",
      editContentHTML: "",

      //檢查標題重複會用到的
      originalTopic: "", //原始標題
      topicExist: false,
    };
  },
  computed: {
    ...mapState(["tab_2"]),
    ...mapGetters({
      length: "tab2Length",
    }),

    items() {
      return this.tab_2.map((item) => {
        let itemNo = item.itemNo;
        let category = item.category;
        let topic = item.topic;
        let content = item.content;
        let mdContent = md.render(item.content);
        let buildDate = item.buildDate;
        let status = item.status === "0" ? "正常" : "停權";
        let edit = "編輯";

        return {
          itemNo,
          topic,
          category,
          content,
          mdContent,
          buildDate,
          status,
          edit,
        };
      });
    },

    isTopicNotEmpty() {
      return this.editTopic.length > 0;
    },

    //檢查標題是否有效
    isTopicValid() {
      return !this.topicExist;
    },

    //檢查編輯的是否為原始的標題，true代表是原始標題
    isOriginalTopic() {
      return this.editTopic === this.originalTopic;
    },

    contentValidation() {
      return this.editContent.length > 0;
    },
  },
  methods: {
    ...mapActions(["backendTab_2"]),

    content(item) {
      this.originalTopic = item.topic; //originalTopic設置為原始標題
      this.editItemNo = item.itemNo;
      this.editCategory = item.category;
      this.editTopic = item.topic;
      this.editStatus = item.status;
      this.editContent = item.content;
      this.editContentHTML = md.render(item.content);
    },

    //取消的按鈕，回復原狀
    resetModal() {
      this.editItemNo = "";
      this.editCategory = "";
      this.editTopic = "";
      this.editStatus = "";
      this.editContent = "";
      this.editContentHTML = "";
    },

    //確認標題是否重複
    checkTopic() {
      //檢查標題是否為空白
      if (!this.isTopicNotEmpty) {
        return;
      }

      let params = new URLSearchParams();
      params.append("topic", this.editTopic);
      axios
        .post("php/add/addCheckTopic.php", params)
        .then((res) => {
          if (res.data == "此標題已經存在!") {
            //標題重複，設置標題無效
            this.topicExist = true;
          } else {
            //標題不重複，設置標題有效
            this.topicExist = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    //確認按鈕的相關檢查，檢查沒問題送出表單
    handleOk(bvModalEvent) {
      // Prevent modal from closing | 阻止預設對話框關閉的預設行為 | bootstrapVue的code
      bvModalEvent.preventDefault();

      //檢查標題是否有重複
      this.checkTopic();

      //如果標題空白
      if (!this.isTopicNotEmpty) {
        return;
        //如果標題重複則不執行後續操作
      } else if (!this.isTopicValid && !this.isOriginalTopic) {
        return;
        //如果內容空白
      } else if (!this.contentValidation) {
        return;
      } else {
        //跳轉開始
        this.loading = true;

        //儲存更新的內容
        this.handleSubmit();
      }
    },

    handleSubmit() {
      let form = new FormData();
      form.append("itemNo", this.editItemNo);
      form.append("category", this.editCategory);
      form.append("topic", this.editTopic);
      form.append("status", this.editStatus);
      form.append("content", this.editContent);

      axios
        .post("php/admin/updateTab2.php", form)
        .then((res) => {
          // console.log(res.data);
          if (res.data == "尚未驗證權限") {
            this.showSwal("帳密錯誤!", "請重新輸入", "error");
            this.$router.push("/logIn");
          } else if (res.data == "更新成功") {
            this.showSwal("更新成功!", "", "success").then(() => {
              window.location.reload();
            });
          } else {
            this.showSwal("修改錯誤!", "請聯絡管理員", "error").then(() => {
              window.location.reload();
              this.loading = false;
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });

      //表單關閉
      this.$nextTick(() => {
        this.$bvModal.hide("modal-xl-tab2");
      });
    },

    //呼叫Swal彈跳視窗
    showSwal(title, text, icon) {
      return Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonText: "確定",
        confirmButtonColor: "#72B9EF",
      });
    },
  },
  watch: {
    //每次editTopic發生變化時檢查是否標題有重複
    editTopic(newValue) {
      if (newValue.trim() !== "") {
        //檢查標題是否為空白
        if (!this.isTopicNotEmpty) {
          return;
        }

        //當編輯的標題不是原始標題時才檢查
        if (!this.isOriginalTopic) {
          this.checkTopic();
        } else {
          //如果是原始標題就直接回重置狀態
          this.topicExist = false;
        }
      }
    },

    editContent(newVal, oldVal) {
      this.editContentHTML = md.render(newVal);
      console.log(oldVal);
    },
  },
  mounted() {
    this.backendTab_2();
  },
};
</script>
