<template>
  <div id="mingContentMed">
    <div class="row m-3 mt-5 search">
      <h5>
        搜尋結果有<span>{{ length }}</span
        >筆
      </h5>
    </div>

    <b-container class="searchArea p-4 pt-0">
      <b-row>
        <b-col
          v-for="(item, index) in search"
          :key="index"
          class="mb-2 box"
          cols="12"
          :data-aos="'fade-down'"
          ><b-card :img-alt="item.topic" img-top tag="article" class="mx-auto">
            <div class="searchContent">
              <div class="img-card" @click="seeMore(item.itemNo)">
                <img :src="item.topicImage" :alt="item.topic" />
              </div>

              <div class="content">
                <h4 class="topic">
                  {{ item.topic }}
                </h4>
                <b-card-text class="date">
                  {{ item.buildDate.slice(0, 10) }}
                </b-card-text>

                <router-link :to="mapping[item.category]">
                  <b-card-text class="category">
                    {{ item.category }}
                  </b-card-text>
                </router-link>

                <b-card-text
                  class="text"
                  v-html="md.render(item.content)"
                ></b-card-text>

                <div class="seeBtn">
                  <b-button class="seeMoreBtn" @click="seeMore(item.itemNo)">
                    看更多
                  </b-button>
                </div>
              </div>
            </div>
          </b-card></b-col
        >

        <!-- 回到最上面 -->
        <GotoTop />
      </b-row>
    </b-container>

    <!-- 顯示社群媒體 -->
    <showMedia />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import md from "@/markdown";
import showMedia from "../../components/showMedia.vue";
import GotoTop from "../../components/GotoTop.vue";
import AOS from "aos";

export default {
  props: ["keyword"],
  data() {
    return {
      rows: null,
      md: md,
      storage: sessionStorage,

      //處裡路徑
      mapping: {
        中醫文章: "medicine",
        中醫穴道: "acupuncture",
        影片: "video",
        吃喝玩樂: "life",
        好物推薦: "category1",
        好吃好玩: "category2",
        生活雜事: "category3",
      },

      title: "部落格 | 張宏銘中醫師鯨生情緣 - 搜尋結果",
    };
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  components: {
    showMedia,
    GotoTop,
  },
  computed: {
    ...mapState(["search"]),

    ...mapGetters({
      length: "searchLength",
    }),
  },
  methods: {
    fetchSearch() {
      this.$store.dispatch("fetchSearch", this.keyword);
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    //看內容
    seeMore(itemNo) {
      this.$router.push({
        name: "mingContent",
        query: {
          sn: itemNo,
        },
      });

      this.saveRecord(itemNo); //儲存到sessionstorage做瀏覽紀錄
    },

    saveRecord(itemNo) {
      //建立key
      if (this.storage["addItemNo"] == null) {
        this.storage["addItemNo"] = "";
      }

      //儲存value
      if (!this.storage[itemNo]) {
        //儲存資料
        this.storage["addItemNo"] += itemNo + ", ";
        // 表示已經儲存過
        this.storage[itemNo] = true;
      }
    },
  },
  watch: {
    $route: "fetchSearch",
  },
  mounted() {
    this.fetchSearch();

    AOS.init({
      duration: 1000,
    });
  },
};
</script>
